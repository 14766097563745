//SubPlayerPage.js
import React from 'react';
import GameLineup from '../../components/GameLineup';

const subPlayerPage = ({ participant, latestEvent, onClose }) => {
    return (
        <div>
            <h2>Substitute Player</h2>
            {/* TODO show speed, or modal with the player card */}
            <GameLineup participant={participant} latestEvent={latestEvent} isSub={true} onClose={onClose} />
        </div>
    );
};

export default subPlayerPage;
