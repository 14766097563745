import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logoutUser } from '../api/auth';

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logoutUser();
            toast.success('Logout successful');
            navigate('/login');
        } catch (error) {
            toast.error('Logout failed: ' + error.message);
            console.error('Logout failed: ', error.response?.data?.error || error.message);
        }
    };

    return <button onClick={handleLogout}>Logout</button>;
};

export default Logout;
