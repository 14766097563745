import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import { toastSuccess, toastError } from '../helpers/toastHelper';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const { login, currentUser } = useAuth();

    const navigate = useNavigate();

    const handleLogin = async e => {
        e.preventDefault();
        setLoading(true);
        try {
            await login(username, password);
            toastSuccess('Login successful');
            navigate('/'); // Redirect to home page on successful login
        } catch (error) {
            toastError('Login failed: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2>Login</h2>
            {currentUser ? (
                <div>Looks like you are already logged in, champ!</div>
            ) : (
                <form onSubmit={handleLogin}>
                    <label>
                        Username:
                        <input type="text" value={username} onChange={e => setUsername(e.target.value)} required />
                    </label>
                    <label>
                        Password:
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                    </label>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
            )}
        </div>
    );
};

export default Login;
