export const sortCardsByRating = cards => {
    return cards.sort((a, b) => b.Card.rating - a.Card.rating);
};

export const getCardOptions = (position, index = null, userCards, lineup, selectedPlayers) => {
    let filteredCards;

    if (['DH', 'Bench'].includes(position)) {
        filteredCards = userCards.filter(
            ({ Card }) => Card && !Card.positions.includes('SP') && !Card.positions.includes('RP')
        );
    } else if (['LF', 'CF', 'RF'].includes(position)) {
        filteredCards = userCards.filter(({ Card }) => Card && Card.positions.includes('OF'));
    } else {
        filteredCards = userCards.filter(({ Card }) => Card && Card.positions.includes(position));
    }

    const selectedCardID = Array.isArray(lineup[position]) ? lineup[position][index]?.cardID : lineup[position]?.cardID;
    const availableCards = filteredCards.filter(
        ({ Card }) => Card && (!selectedPlayers.has(Card.playerID) || Card.cardID == selectedCardID)
    );

    return sortCardsByRating(availableCards).map(({ Card }) => ({
        key: Card.cardID,
        value: Card.cardID,
        label: `${Card.year} ${Card.name} (${Card.rating}) (${Card.positions})`,
    }));
};

export const calculateScore = (lineup, userCards) => {
    let totalRating = 0;
    let count = 0;

    const addRating = cardID => {
        if (cardID) {
            const card = userCards.find(({ Card }) => Card.cardID == cardID);
            if (card) {
                totalRating += card.Card.rating;
                count += 1;
            }
        }
    };

    Object.values(lineup).forEach(position => {
        if (Array.isArray(position)) {
            position.forEach(({ cardID }) => addRating(cardID));
        } else {
            addRating(position.cardID);
        }
    });

    return count > 0 ? Math.round(totalRating / count) : 0;
};

export const lineupOrderOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const positions = [
    { label: 'C', type: 'single' },
    { label: '1B', type: 'single' },
    { label: '2B', type: 'single' },
    { label: '3B', type: 'single' },
    { label: 'SS', type: 'single' },
    { label: 'LF', type: 'single' },
    { label: 'CF', type: 'single' },
    { label: 'RF', type: 'single' },
    { label: 'DH', type: 'single' },
    { label: 'SP', type: 'array', count: 5 },
    { label: 'RP', type: 'array', count: 5 },
    { label: 'Bench', type: 'array', count: 5 },
];

export const createInitialLineup = () => {
    const initialLineup = {};
    positions.forEach(({ label, type, count }) => {
        if (type === 'single') {
            initialLineup[label] = { cardID: '', lineupOrder: '' };
        } else {
            initialLineup[label] = Array(count).fill({ cardID: '', lineupOrder: '' });
        }
    });
    initialLineup.lineupOrder = Array(9).fill('');
    return initialLineup;
};
