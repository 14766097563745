import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchAllRewards, updateReward } from '../../api/rewards';
import { fetchAllSeries } from '../../api/series';
import 'react-toastify/dist/ReactToastify.css';

const ViewRewardsPage = () => {
    const [rewards, setRewards] = useState([]);
    const [editingRewardID, setEditingRewardID] = useState(null); // Track the editing reward ID
    const [editableRewardData, setEditableRewardData] = useState({}); // Track the form data for inline editing
    const [series, setSeries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            try {
                const rewards = await fetchAllRewards();
                const fetchedSeries = await fetchAllSeries();
                setRewards(rewards);
                setSeries(fetchedSeries);
            } catch (error) {
                setError('Error fetching rewards');
                toast.error('Error fetching rewards: ' + error.message);
            }
        };

        fetchData();
    }, []);

    const handleEditClick = reward => {
        setEditingRewardID(reward.rewardID);
        setEditableRewardData(reward); // Set the reward's current data into the form
    };

    const handleCancelClick = () => {
        setEditingRewardID(null);
        setEditableRewardData({});
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setEditableRewardData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleUpdateClick = async rewardID => {
        setLoading(true);
        try {
            await updateReward(rewardID, editableRewardData);
            toast.success('Reward updated successfully!');
            setEditingRewardID(null); // Reset the editing mode
            // Update the rewards list with the updated data
            setRewards(prevRewards =>
                prevRewards.map(reward =>
                    reward.rewardID === rewardID ? { ...reward, ...editableRewardData } : reward
                )
            );
        } catch (error) {
            toast.error('Error updating reward: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <div className="view-rewards-page">
            <h2>
                View Rewards /<Link to="/rewards/create">Create Rewards</Link>
            </h2>
            {rewards.length === 0 ? (
                <p>No rewards found.</p>
            ) : (
                <table className="rewards-table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Money</th>
                            <th>Series</th>
                            <th>Number of Packs</th>
                            <th>Card</th>
                            <th>Series Pct</th>
                            <th>Card Pct</th>
                            <th>Description</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rewards.map(reward => (
                            <tr key={reward.rewardID}>
                                {editingRewardID === reward.rewardID ? (
                                    <>
                                        <td>{reward.rewardID}</td>
                                        <td>
                                            <input
                                                type="number"
                                                name="money"
                                                value={editableRewardData.money}
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                name="seriesID"
                                                value={editableRewardData.seriesID}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select a series</option>
                                                {series.map(s => (
                                                    <option key={s.seriesID} value={s.seriesID}>
                                                        {s.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="numberOfSeries"
                                                value={editableRewardData.numberOfSeries || ''}
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="cardID"
                                                value={editableRewardData.cardID || ''}
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="seriesPct"
                                                value={editableRewardData.seriesPct || ''}
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="cardPct"
                                                value={editableRewardData.cardPct || ''}
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="description"
                                                value={editableRewardData.description || ''}
                                                onChange={handleChange}
                                            />
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => handleUpdateClick(reward.rewardID)}
                                                disabled={loading}
                                            >
                                                {loading ? 'Updating...' : 'Update'}
                                            </button>
                                            <button onClick={handleCancelClick}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{reward.rewardID}</td>
                                        <td>{reward.money}</td>
                                        <td>{reward.Series?.name}</td>
                                        <td>{reward.numberOfSeries || ''}</td>
                                        <td>
                                            {reward.Card?.year} {reward.Card?.name}
                                        </td>
                                        <td>{reward.seriesPct}</td>
                                        <td>{reward.cardPct}</td>
                                        <td>{reward.description}</td>
                                        <td>
                                            <button onClick={() => handleEditClick(reward)}>Edit</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default ViewRewardsPage;
