// src/components/CardTable.js
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SortableTable from './SortableTable';
import { getCardRarityClass } from '../utils/cardUtils';

const CardTable = ({ cards, requestSort, sortConfig }) => (
    <SortableTable
        data={cards}
        requestSort={requestSort}
        sortConfig={sortConfig}
        columns={[
            {
                key: 'name',
                label: 'Name',
                render: card => (
                    <Link to={`/cards/${card.cardID}`} state={{ card }}>
                        <span className={`square ${getCardRarityClass(card.rating)}`}></span>
                        {decodeURIComponent(card.name)}
                    </Link>
                ),
            },
            { key: 'year', label: 'Year' },
            { key: 'type', label: 'Type' },
            { key: 'teamNameID', label: 'Team' },
            { key: 'positions', label: 'Pos' },
            { key: 'rating', label: 'Rati' },
            { key: 'amount', label: 'Quant' },
        ]}
    />
);

CardTable.propTypes = {
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            cardID: PropTypes.any.isRequired,
            name: PropTypes.string.isRequired,
            year: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
            teamNameID: PropTypes.any.isRequired,
            positions: PropTypes.string.isRequired,
            rating: PropTypes.number.isRequired,
            amount: PropTypes.number,
        })
    ).isRequired,
    requestSort: PropTypes.func.isRequired,
    sortConfig: PropTypes.shape({
        key: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired,
    }).isRequired,
};

export default CardTable;
