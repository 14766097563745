import axios from './axios';

export const registerUser = async (username, email, password) => {
    try {
        const response = await axios.post('/users', { username, email, password });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const loginUser = async (username, password) => {
    try {
        const response = await axios.post('/auth/login', { username, password });
        //console.log('loginUser - response:', response.data); // Add logging here
        return response.data;
    } catch (error) {
        console.error('loginUser - error:', error.response?.data || error.message);
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const logoutUser = async () => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        //console.log('accessToken', accessToken);
        if (!accessToken) {
            return;
        }
        await axios.post('/auth/logout', { accessToken });
        localStorage.removeItem('accessToken');
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchUserDetails = async accessToken => {
    try {
        //console.log('fetchUserDetails - accessToken:', accessToken);
        const response = await axios.get('/users/me', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        //console.log('fetchUserDetails - response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user details:', error);
        return null;
    }
};
