import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import CardTable from '../../components/CardTable';
import { fetchCardsByUserID } from '../../api/cards';
import { TEAMS } from '../../helpers/teamHelpers';

const UserCardsPage = () => {
    const { currentUser } = useAuth();
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'rating', direction: 'descending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(100);
    const [totalCards, setTotalCards] = useState(0);
    const [filters, setFilters] = useState({ year: '', rating: '', team: '' });

    useEffect(() => {
        const fetchUserCards = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await fetchCardsByUserID(currentPage, pageSize, filters);
                const userCards = data.cards.map(cardInventory => ({
                    ...cardInventory.Card,
                    amount: cardInventory.amount,
                }));
                setCards(userCards);
                setTotalCards(data.totalCards);
            } catch (error) {
                setError('Error fetching user cards');
                console.error('Error fetching user cards:', error);
            } finally {
                setLoading(false);
            }
        };

        if (currentUser) {
            fetchUserCards();
        }
    }, [currentUser, currentPage, pageSize, filters]);

    const handleFilterChange = newFilters => {
        setFilters(newFilters);
        setCurrentPage(1); // Reset to the first page when filters change
    };

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((o, i) => (o ? o[i] : null), obj);
    };

    const sortedCards = [...cards].sort((a, b) => {
        const aValue = getNestedValue(a, sortConfig.key);
        const bValue = getNestedValue(b, sortConfig.key);

        if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const totalPages = Math.ceil(totalCards / pageSize);

    return (
        <div className="user-cards-page">
            {error && <p className="error">{error}</p>}
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <h2>
                        My Cards{' '}
                        <button>
                            <Link to="/cards/sell">Sell Cards</Link>
                        </button>
                    </h2>
                    <div className="card-filter">
                        <label>
                            Year:
                            <input
                                type="number"
                                value={filters.year}
                                onChange={e => handleFilterChange({ ...filters, year: e.target.value })}
                            />
                        </label>
                        <label>
                            Rating:
                            <select
                                value={filters.rating}
                                onChange={e => handleFilterChange({ ...filters, rating: e.target.value })}
                            >
                                <option value="">Select rating</option>
                                <option value="96">96+</option>
                                <option value="86">86+</option>
                                <option value="76">76+</option>
                                <option value="66">66+</option>
                                <option value="56">56+</option>
                                <option value="0">all</option>
                            </select>
                        </label>
                        <label>
                            Team:
                            <select
                                value={filters.team}
                                onChange={e => handleFilterChange({ ...filters, team: e.target.value })}
                            >
                                <option value="">Select team</option>
                                {Object.entries(TEAMS).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="pagination">
                        <button
                            className="boring_button"
                            onClick={() => setCurrentPage(1)}
                            disabled={currentPage === 1}
                        >
                            &lt;&lt;
                        </button>
                        <button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="boring_button"
                        >
                            &lt;
                        </button>
                        <span>
                            {currentPage} / {totalPages}
                        </span>
                        <button
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                            className="boring_button"
                        >
                            &gt;
                        </button>
                        <button
                            onClick={() => setCurrentPage(totalPages)}
                            disabled={currentPage === totalPages}
                            className="boring_button"
                        >
                            &gt;&gt;
                        </button>
                    </div>
                    <CardTable
                        cards={sortedCards}
                        requestSort={requestSort}
                        sortConfig={sortConfig}
                        columns={[
                            { key: 'name', label: 'Name' },
                            { key: 'year', label: 'Year' },
                            { key: 'type', label: 'Type' },
                            { key: 'teamNameID', label: 'Team' },
                            { key: 'positions', label: 'Pos' },
                            { key: 'rating', label: 'Rat' },
                            { key: 'amount', label: 'Quant' },
                        ]}
                    />
                </>
            )}
        </div>
    );
};

export default UserCardsPage;
