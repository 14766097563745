//SubPlayerPage.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GLOBALS from '../../config/globals';
import { managedEvent } from '../../api/games';
import { translateOuts, translateEvent } from '../../utils/gameUtils';

const StealEventPage = ({ latestEvent, onClose }) => {
    const [submitting, setSubmitting] = useState(false);
    const handleStealEventChoice = async runnersAdvancing => {
        try {
            setSubmitting(true);
            await managedEvent({
                event: GLOBALS.MANAGED_EVENTS.STEAL.name,
                runnersAdvancing,
                gameID: latestEvent.gameID,
            });
            onClose();
        } catch (err) {
            toast.error(`Error: ${err.message}`);
        } finally {
            setSubmitting(false);
        }
    };
    const firstRunner = latestEvent.firstBaseRunnerLineup
        ? `${latestEvent.firstBaseRunnerLineup.Card.year} ${latestEvent.firstBaseRunnerLineup.Card.name} - Speed ${latestEvent.firstBaseRunnerLineup.Card.speed}`
        : null;
    const secondRunner = latestEvent.secondBaseRunnerLineup
        ? `${latestEvent.secondBaseRunnerLineup.Card.year} ${latestEvent.secondBaseRunnerLineup.Card.name} - Speed ${latestEvent.secondBaseRunnerLineup.Card.speed}`
        : null;
    const thirdRunner = latestEvent.thirdBaseRunnerLineup
        ? `${latestEvent.thirdBaseRunnerLineup.Card.year} ${latestEvent.thirdBaseRunnerLineup.Card.name} - Speed ${latestEvent.thirdBaseRunnerLineup.Card.speed}`
        : null;
    const advancingRunners = [];
    if (firstRunner) {
        advancingRunners.push(1);
    }
    if (secondRunner) {
        advancingRunners.push(2);
    }
    if (thirdRunner) {
        advancingRunners.push(3);
    }

    const advancingRunnerOptions = [];
    const advancingRunnerText = [];
    if (advancingRunners.includes(1)) {
        if (advancingRunners.includes(2)) {
            if (advancingRunners.includes(3)) {
                advancingRunnerOptions.push('1,2,3');
                advancingRunnerText.push('Steal 2nd, 3rd and Home!');
            } else {
                advancingRunnerOptions.push('1,2');
                advancingRunnerText.push('Steal 2nd and 3rd');
            }
        } else {
            advancingRunnerOptions.push('1');
            advancingRunnerText.push('Steal 2nd');
        }
    }

    if (advancingRunners.includes(2)) {
        if (advancingRunners.includes(3)) {
            advancingRunnerOptions.push('2,3');
            advancingRunnerText.push('Steal 3rd and Home!');
        } else {
            advancingRunnerOptions.push('2');
            advancingRunnerText.push('Steal 3rd');
        }
    }

    if (advancingRunners.includes(3)) {
        advancingRunnerOptions.push('3');
        advancingRunnerText.push('Steal Home!');
    }

    if (advancingRunners.includes(3) && advancingRunners.includes(1)) {
        advancingRunnerOptions.push('1,3');
        advancingRunnerText.push('Steal 2nd and Home!');
    }
    return (
        <div>
            <h2>Steal!?</h2>
            <div>
                <b>
                    {translateOuts(latestEvent.ipOuts).complete} {translateEvent(latestEvent.resultType)}!
                </b>
                <br />
                {latestEvent.resultDescription}
            </div>
            <div>
                <br />
                1st: {firstRunner}
            </div>
            <div>2nd: {secondRunner}</div>
            <div>3rd: {thirdRunner}</div>
            {advancingRunnerOptions.length ? (
                <>
                    <br />
                    {advancingRunnerOptions.map((opt, idx) => {
                        return (
                            <>
                                <button
                                    key={idx}
                                    onClick={() => handleStealEventChoice(opt)}
                                    className={submitting ? 'inactive' : ''}
                                >
                                    {advancingRunnerText[idx]}
                                </button>
                                <br />
                            </>
                        );
                    })}
                    <button onClick={() => onClose()}>Cancel Steal</button>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

StealEventPage.propTypes = {
    latestEvent: PropTypes.shape({
        gameID: PropTypes.number.isRequired,
        resultDescription: PropTypes.string.isRequired,
        resultType: PropTypes.string.isRequired,
        ipOuts: PropTypes.number.isRequired,
        firstBaseRunnerLineup: PropTypes.shape({
            Card: PropTypes.shape({
                year: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                speed: PropTypes.number.isRequired,
            }),
        }),
        secondBaseRunnerLineup: PropTypes.shape({
            Card: PropTypes.shape({
                year: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                speed: PropTypes.number.isRequired,
            }),
        }),
        thirdBaseRunnerLineup: PropTypes.shape({
            Card: PropTypes.shape({
                year: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                speed: PropTypes.number.isRequired,
            }),
        }),
    }).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default StealEventPage;
