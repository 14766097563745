import React, { useState, useEffect } from 'react';
import { fetchUsers, awardPackToUser, awardMoneyToUser } from '../../api/user';
import { fetchAllSeries } from '../../api/series';
import { toast } from 'react-toastify';

const AdminUserManagementPage = () => {
    const [users, setUsers] = useState([]);
    const [packs, setPacks] = useState([]); // State for all static packs
    const [loading, setLoading] = useState(true);
    const [awardMoney, setAwardMoney] = useState({});
    const [awardPack, setAwardPack] = useState({});

    // Fetch users when component mounts
    useEffect(() => {
        const getUsers = async () => {
            setLoading(true);
            try {
                const data = await fetchUsers(); // Fetches users from the API
                setUsers(data.users);
            } catch (error) {
                toast.error('Error fetching users');
            } finally {
                setLoading(false);
            }
        };

        getUsers();
    }, []);

    // Fetch static packs (all series)
    useEffect(() => {
        const getPacks = async () => {
            try {
                const data = await fetchAllSeries(); // Fetches all packs from the API
                setPacks(data);
            } catch (error) {
                toast.error('Error fetching packs:', error);
            }
        };

        getPacks();
    }, []);

    // Handles awarding a static pack to the user
    const handleAwardPack = async userID => {
        if (awardPack[userID]) {
            try {
                await awardPackToUser({ userID, seriesID: awardPack[userID] }); // API call to award the pack
                toast.success(`Pack awarded to user with ID: ${userID}`);
            } catch (error) {
                toast.error('Error awarding pack:', error);
            }
        }
    };

    // Handles awarding money to the user
    const handleAwardMoney = async userID => {
        //{ userID, amount, reason }
        if (awardMoney[userID]) {
            try {
                await awardMoneyToUser({ userID, amount: awardMoney[userID] }); // API call to award money
                toast.success(`Money awarded to user with ID: ${userID}`);
            } catch (error) {
                toast.error('Error awarding money:', error);
            }
        }
    };

    if (loading) {
        return <p>Loading...</p>; // Show loading spinner or message when fetching data
    }

    return (
        <div className="admin-user-management-page">
            <h2>Admin User Management</h2>

            {/* Grid of users with action options */}
            <table className="user-grid">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Last Login</th>
                        <th>Award Pack</th>
                        <th>Award Money</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.userID}>
                            <td>{user.username}</td>
                            <td>{user.lastLogin}</td>

                            {/* Award Pack dropdown and button */}
                            <td>
                                <select
                                    value={awardPack[user.userID] || ''}
                                    onChange={e => setAwardPack({ ...awardPack, [user.userID]: e.target.value })}
                                >
                                    <option value="">-- Select Pack --</option>
                                    {packs.map(pack => (
                                        <option key={pack.seriesID} value={pack.seriesID}>
                                            {pack.name}
                                        </option>
                                    ))}
                                </select>
                                <button onClick={() => handleAwardPack(user.userID)}>Award Pack</button>
                            </td>

                            {/* Award Money input and button */}
                            <td>
                                <input
                                    type="number"
                                    value={awardMoney[user.userID] || ''}
                                    onChange={e => setAwardMoney({ ...awardMoney, [user.userID]: e.target.value })}
                                    placeholder="Enter amount"
                                />
                                <button onClick={() => handleAwardMoney(user.userID)}>Award Money</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminUserManagementPage;
