import React, { useEffect, useState } from 'react';
import { fetchAllYearsAndTeams, buildCompTeam } from '../../api/userTeams';
import { toast } from 'react-toastify';
import { TEAMS } from '../../helpers/teamHelpers';
import { Link } from 'react-router-dom';

const CreateCompTeamPage = () => {
    const [yearTeamData, setYearTeamData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamsForSelectedYear, setTeamsForSelectedYear] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchAllYearsAndTeams();
                data.sort((a, b) => a.yearID - b.yearID); // Sort by year numerically
                setYearTeamData(data);
            } catch (error) {
                console.error('Error fetching year and team data:', error);
                setError('Error fetching year and team data.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedYear) {
            const teams = yearTeamData
                .filter(yt => yt.yearID === selectedYear)
                .map(yt => yt.teamID)
                .sort(); // Sort teams alphabetically
            setTeamsForSelectedYear(teams);
        } else {
            setTeamsForSelectedYear([]);
        }
    }, [selectedYear, yearTeamData]);

    const handleSubmit = async () => {
        if (selectedYear && selectedTeam) {
            try {
                const response = await buildCompTeam(selectedYear, selectedTeam);
                console.log('Submission successful:', response);
                toast.success('Submission successful!');
            } catch (error) {
                console.error('Error submitting year and team:', error);
                toast.error('Error submitting year and team.');
            }
        } else {
            alert('Please select both a year and a team');
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <div>
            <h1>Select Year and Team</h1>
            <div>
                <label htmlFor="year-select">Year:</label>
                <select id="year-select" onChange={e => setSelectedYear(parseInt(e.target.value))}>
                    <option value="">Select a year</option>
                    {[...new Set(yearTeamData.map(yt => yt.yearID))]
                        .sort((a, b) => a - b)
                        .map(
                            (
                                year // Sort years numerically
                            ) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            )
                        )}
                </select>
            </div>
            <div>
                <label htmlFor="team-select">Team:</label>
                <select id="team-select" onChange={e => setSelectedTeam(e.target.value)} disabled={!selectedYear}>
                    <option value="">Select a team</option>
                    {teamsForSelectedYear.map(team => (
                        <option key={team} value={team}>
                            {TEAMS[team] || team}
                        </option>
                    ))}
                </select>
            </div>
            <button onClick={handleSubmit}>Submit</button>
            <div>
                <br />
                <Link to="/team/view-comp-teams">View Comp Teams</Link>
            </div>
        </div>
    );
};

export default CreateCompTeamPage;
