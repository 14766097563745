import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { createReward } from '../../api/rewards';
import { fetchAllSeries } from '../../api/series';

const CreateRewardPage = () => {
    const [loading, setLoading] = useState(false);
    const [rewardData, setRewardData] = useState({
        money: '',
        seriesID: '',
        numberOfSeries: '',
        cardID: '',
        seriesPct: '',
        cardPct: '',
        description: '',
    });

    const [series, setSeries] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedSeries = await fetchAllSeries();
                setSeries(fetchedSeries);
            } catch (error) {
                toast.error('Error fetching data: ' + error.message);
            }
        };

        fetchData();
    }, []);

    const handleChange = e => {
        const { name, value } = e.target;
        setRewardData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        try {
            await createReward(rewardData);
            toast.success('Reward created successfully!');
            setRewardData({
                money: '',
                seriesID: '',
                numberOfSeries: '',
                cardID: '',
                seriesPct: '',
                cardPct: '',
                description: '',
            });
        } catch (error) {
            toast.error('Error creating reward: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-reward-page">
            <h2>Create Reward</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Money:
                    <input type="number" name="money" value={rewardData.money} onChange={handleChange} />
                </label>
                <label>
                    Series:
                    <select name="seriesID" value={rewardData.seriesID} onChange={handleChange}>
                        <option value="">Select a series</option>
                        {series.map(s => (
                            <option key={s.seriesID} value={s.seriesID}>
                                {s.name}
                            </option>
                        ))}
                    </select>
                </label>
                <label>
                    Number of Series:
                    <input
                        type="number"
                        name="numberOfSeries"
                        value={rewardData.numberOfSeries}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Card:
                    <input type="number" name="cardID" value={rewardData.cardID} onChange={handleChange} />
                </label>
                <label>
                    Series Percentage:
                    <input type="number" name="seriesPct" value={rewardData.seriesPct} onChange={handleChange} />
                </label>
                <label>
                    Card Percentage:
                    <input type="number" name="cardPct" value={rewardData.cardPct} onChange={handleChange} />
                </label>
                <label>
                    Description:
                    <textarea
                        name="description"
                        value={rewardData.description}
                        onChange={handleChange}
                        rows="4"
                        cols="50"
                        placeholder="Enter a description for the reward"
                    />
                </label>
                <button type="submit" disabled={loading}>
                    {loading ? 'Creating...' : 'Create Reward'}
                </button>
            </form>
        </div>
    );
};

export default CreateRewardPage;
