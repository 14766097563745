import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AdminRoute = () => {
    const { currentUser } = useAuth();
    const isAuthenticated = !!localStorage.getItem('accessToken');

    return isAuthenticated && currentUser?.isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoute;
