// src/pages/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
    return (
        <div className="container">
            <h1 className="header">Uranium Baseball</h1>
            <p className="paragraph">
                <h3>Things to do first:</h3>
                <ul>
                    <li>
                        <Link to="/packs/buy">Go buy some packs</Link>
                    </li>
                    <li>
                        <Link to="/team/user">Put together your team</Link>
                    </li>
                    <li>
                        <Link to="/campaigns">Go play some of the worst teams</Link>
                    </li>
                </ul>
            </p>
        </div>
    );
};

export default HomePage;
