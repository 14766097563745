// src/pages/admin/AdminDiceRollPage.js
import React, { useState, useEffect } from 'react';
import { fetchCards } from '../../api/cards';
import DiceRollerVs from '../../components/DiceRollerVs';
import Card from '../../components/Card';

const AdminDiceRollPage = () => {
    const [cards, setCards] = useState([]);
    const [pitchingCards, setPitchingCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedPitcherCard, setSelectedPitcherCard] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getCards = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await fetchCards();
                const battingCards = data.filter(card => card.type === 'Batter').sort((a, b) => b.rating - a.rating);
                const pitcherCards = data.filter(card => card.type === 'Pitcher').sort((a, b) => b.rating - a.rating);
                setCards(battingCards);
                setPitchingCards(pitcherCards);
            } catch (error) {
                setError('Error fetching cards');
                console.error('Error fetching cards:', error);
            } finally {
                setLoading(false);
            }
        };

        getCards();
    }, []);

    const handleCardChange = e => {
        const cardID = e.target.value;
        const card = cards.find(c => c.cardID == cardID);
        //console.log('card', card);
        setSelectedCard(card);
    };

    const handlePitcherCardChange = e => {
        const pCardID = e.target.value;
        const pCard = pitchingCards.find(c => c.cardID == pCardID);
        //console.log('pCard', pCard);
        setSelectedPitcherCard(pCard);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <div className="admin-dice-roll-page">
            <h2>Admin Dice Roll</h2>

            {/* Flex container for the selects and cards */}
            <div className="selects-and-cards">
                <div className="card-selection">
                    <label htmlFor="batterSelect">Select Batter:</label>
                    <select id="batterSelect" onChange={handleCardChange}>
                        <option value="">-- Select a card --</option>
                        {cards.map(card => (
                            <option key={card.cardID} value={card.cardID}>
                                ({card.rating}) {card.name} ({card.year})
                            </option>
                        ))}
                    </select>
                    <br />
                    <label htmlFor="pitcherSelect">Select Pitcher:</label>
                    <select id="pitcherSelect" onChange={handlePitcherCardChange}>
                        <option value="">-- Select a card --</option>
                        {pitchingCards.map(card => (
                            <option key={card.cardID} value={card.cardID}>
                                ({card.rating}) {card.name} ({card.year})
                            </option>
                        ))}
                    </select>
                    <br />
                    {/* Dice roller below the selects and cards */}
                    {selectedCard && selectedPitcherCard && (
                        <div className="dice-roller-container">
                            <DiceRollerVs battingCard={selectedCard} pitchingCard={selectedPitcherCard} />
                        </div>
                    )}
                </div>

                {/* Display the selected cards side by side */}
                {selectedCard && selectedPitcherCard && (
                    <div className="cards-display">
                        <Card card={selectedCard} />
                        <Card card={selectedPitcherCard} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminDiceRollPage;
