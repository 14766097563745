// src/utils/statsUtils.js
import GLOBALS from '../config/globals';

export const calculateBattingStats = rollResults => {
    const stats = {
        h1: 0,
        h2: 0,
        h3: 0,
        hr: 0,
        so: 0,
        oo: 0,
        bb: 0,
        ab: 0,
        h: 0,
        tb: 0, // Total bases
        pa: 0, // Plate appearances
    };

    rollResults.forEach(result => {
        stats.pa += 1;
        switch (result) {
            case 'h1':
                stats.h1 += 1;
                stats.h += 1;
                stats.tb += 1;
                stats.ab += 1;
                break;
            case 'h2':
                stats.h2 += 1;
                stats.h += 1;
                stats.tb += 2;
                stats.ab += 1;
                break;
            case 'h3':
                stats.h3 += 1;
                stats.h += 1;
                stats.tb += 3;
                stats.ab += 1;
                break;
            case 'hr':
                stats.hr += 1;
                stats.h += 1;
                stats.tb += 4;
                stats.ab += 1;
                break;
            case 'so':
                stats.so += 1;
                stats.ab += 1;
                break;
            case 'oo':
                stats.oo += 1;
                stats.ab += 1;
                break;
            case 'bb':
                stats.bb += 1;
                break;
            default:
                stats.oo += 1;
                stats.ab += 1;
                break;
        }
    });

    const average = stats.ab > 0 ? (stats.h / stats.ab).toFixed(3) : '0.000';
    const obp = stats.pa > 0 ? ((stats.h + stats.bb) / stats.pa).toFixed(3) : '0.000';
    const slugging = stats.ab > 0 ? (stats.tb / stats.ab).toFixed(3) : '0.000';
    const ops = (parseFloat(obp) + parseFloat(slugging)).toFixed(3);

    return {
        ...stats,
        average,
        obp,
        slugging,
        ops,
    };
};

export const rollDiceForCard = (card, numRolls) => {
    const results = [];
    const rollTwoDice = () => {
        const die1 = getRandomInt(1, 6);
        const die2 = getRandomInt(1, 6);
        return die1 + die2;
    };
    for (let i = 0; i < numRolls; i++) {
        const roll = rollTwoDice();
        const result = card[`d${roll}`];
        if (result === 'xb') {
            const xbRoll = rollTwoDice();
            results.push(card[`xd${xbRoll}`]);
        } else {
            results.push(result);
        }
    }
    return results;
};

export const rollDiceForCardVs = (battingCard, pitchingCard, numRolls) => {
    const results = [];
    const preResults = [];

    const rollTwoDice = () => {
        const die1 = getRandomInt(1, 6);
        const die2 = getRandomInt(1, 6);
        return die1 + die2;
    };

    for (let i = 0; i < numRolls; i++) {
        const roll = rollTwoDice();
        let bResult = battingCard[`d${roll}`];
        const batterResultReroll = rollTwoDice();
        const pRoll = rollTwoDice();
        const pResult = pitchingCard[`pd${pRoll}`];
        if (bResult === 'xb') {
            const xbRoll = rollTwoDice();
            bResult = battingCard[`xd${xbRoll}`];
        }
        preResults.push(bResult);
        results.push(getFinalResult(bResult, batterResultReroll, pResult));
    }

    return { results, preResults };
};

export const getFinalResult = (batterResult, batterResultReroll, pitcherResult) => {
    if (!pitcherResult) {
        return batterResult;
    }

    const [action, direction] = pitcherResult.split('-');
    switch (action) {
        case 'h9':
            return affectH(batterResult, direction);
        case 'bb9':
            return affectbb(batterResult, direction);
        case 'hr9':
            return affecthr(batterResult, direction);
        case 'k9':
            return affectK(batterResult, direction, batterResultReroll);
        default:
            return batterResult;
    }
};

export const affectbb = (preresult, direction) => {
    if (direction === 'h') {
        if ([GLOBALS.SO].includes(preresult)) {
            return GLOBALS.BB;
        }
    } else {
        if ([GLOBALS.BB].includes(preresult)) {
            return GLOBALS.OO; //SO OR OO?
        }
    }
    return preresult;
};

export const affectH = (preresult, direction) => {
    if (direction === 'h') {
        if (preresult === GLOBALS.OO) {
            return GLOBALS.H1;
        }
    } else {
        if (preresult === GLOBALS.H1) {
            return GLOBALS.OO;
        }
    }
    return preresult;
};

export const affectK = (preresult, direction, batterResultReroll) => {
    if (direction === 'h') {
        if (preresult === GLOBALS.OO && Math.random() > GLOBALS.K_AFFECT_PCT) {
            return GLOBALS.SO;
        }
        if (preresult === GLOBALS.BB && Math.random() > GLOBALS.K_AFFECT_PCT) {
            //we make need to tweak this
            return GLOBALS.SO;
        }
    } else {
        if (preresult === GLOBALS.SO) {
            return batterResultReroll;
        }
    }
    return preresult;
};

export const affecthr = (preresult, direction) => {
    if (direction === 'h') {
        if (preresult === GLOBALS.H2) {
            return GLOBALS.HR;
        }
        if (preresult === GLOBALS.H1) {
            return GLOBALS.H2;
        }
    } else {
        if (preresult === GLOBALS.HR) {
            return GLOBALS.H2;
        }
        if (preresult === GLOBALS.H2) {
            return GLOBALS.H1;
        }
    }
    return preresult;
};

const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const combineBattingRecords = battings => {
    if (!battings || battings.length === 0) return null;

    return battings.reduce(
        (acc, curr) => {
            acc.AB += curr.AB ?? 0;
            acc.G += curr.G ?? 0;
            acc.H += curr.H ?? 0;
            acc['2B'] += curr['2B'] ?? 0;
            acc['3B'] += curr['3B'] ?? 0;
            acc.HR += curr.HR ?? 0;
            acc.R += curr.R ?? 0;
            acc.RBI += curr.RBI ?? 0;
            acc.SB += curr.SB ?? 0;
            acc.CS += curr.CS ?? 0;
            acc.BB += curr.BB ?? 0;
            acc.SO += curr.SO ?? 0;
            acc.IBB += curr.IBB ?? 0;
            acc.HBP += curr.HBP ?? 0;
            acc.SH += curr.SH ?? 0;
            acc.SF += curr.SF ?? 0;
            acc.GIDP += curr.GIDP ?? 0;
            return acc;
        },
        {
            AB: 0,
            G: 0,
            H: 0,
            '2B': 0,
            '3B': 0,
            HR: 0,
            R: 0,
            RBI: 0,
            SB: 0,
            CS: 0,
            BB: 0,
            SO: 0,
            IBB: 0,
            HBP: 0,
            SH: 0,
            SF: 0,
            GIDP: 0,
        }
    );
};

export const combinePitchingRecords = pitchings => {
    if (!pitchings || pitchings.length === 0) return null;

    return pitchings.reduce(
        (acc, curr) => {
            acc.IPouts += curr.IPouts ?? 0;
            acc.ER += curr.ER ?? 0;
            acc.BB += curr.BB ?? 0;
            acc.H += curr.H ?? 0;
            acc.SO += curr.SO ?? 0;
            acc.W += curr.W ?? 0;
            acc.L += curr.L ?? 0;
            acc.G += curr.G ?? 0;
            acc.GS += curr.GS ?? 0;
            acc.CG += curr.CG ?? 0;
            acc.SHO += curr.SHO ?? 0;
            acc.SV += curr.SV ?? 0;
            acc.HR += curr.HR ?? 0;
            return acc;
        },
        {
            IPouts: 0,
            ER: 0,
            BB: 0,
            H: 0,
            SO: 0,
            W: 0,
            L: 0,
            G: 0,
            GS: 0,
            CG: 0,
            SHO: 0,
            SV: 0,
            HR: 0,
        }
    );
};
