import axios from 'axios';
//import { logoutUser } from './auth'; // Adjust the import path as necessary

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => response,
    async error => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.error === 'jwt expired') {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('idToken');
            localStorage.removeItem('refreshToken');
            window.location.href = '/login'; // Redirect to login page or any other desired action
        }
        return Promise.reject(error);
    }
);

export default instance;
