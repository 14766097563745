//api/reward.js
import axios from './axios';

export const fetchAllRewards = async () => {
    try {
        const response = await axios.get('/reward');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const createReward = async rewardData => {
    try {
        const response = await axios.post('/reward/create', rewardData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const updateReward = async (rewardID, rewardData) => {
    try {
        const response = await axios.put(`/reward/${rewardID}`, rewardData); // PUT request to update the reward
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};
