// src/pages/ViewCompTeams.js
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchCompTeams } from '../../api/userTeams';
import TeamList from '../../components/TeamList';

const ViewCompTeams = () => {
    const [teams, setTeams] = useState([]);

    const fetchComputerTeams = useCallback(async () => {
        try {
            const compTeams = await fetchCompTeams();
            setTeams(compTeams);
        } catch (error) {
            console.error('Error fetching computer teams:', error);
        }
    }, []);

    useEffect(() => {
        fetchComputerTeams();
    }, [fetchComputerTeams]);

    return (
        <div className="user-teams-page">
            <h2>Computer Teams</h2>
            <TeamList teams={teams} />
            <div>
                <br />
                <Link to="/team/comp-team">Create Comp Teams</Link>
            </div>
        </div>
    );
};

export default ViewCompTeams;
