// src/components/DiceRoller.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { rollDiceForCardVs, calculateBattingStats } from '../utils/statsUtils';

const DiceRollerVs = ({ battingCard, pitchingCard }) => {
    const [numRolls, setNumRolls] = useState(1);
    const [rollResults, setRollResults] = useState([]);
    const [preRollResults, setPreRollResults] = useState([]);

    const handleRollDice = () => {
        if (battingCard) {
            const { results, preResults } = rollDiceForCardVs(battingCard, pitchingCard, numRolls);
            setRollResults(results);
            setPreRollResults(preResults);
        }
    };

    const handleNumRollsChange = e => {
        setNumRolls(parseInt(e.target.value));
    };

    const battingStats = calculateBattingStats(rollResults);
    const preBattingStats = calculateBattingStats(preRollResults);

    return (
        <div className="dice-roller">
            <div className="num-rolls">
                <label htmlFor="numRolls">Number of Rolls:</label>
                <input type="number" id="numRolls" value={numRolls} onChange={handleNumRollsChange} min="1" />
            </div>
            <button onClick={handleRollDice} disabled={!battingCard}>
                Roll Dice
            </button>

            {/* Container for both pre-roll and roll results */}
            <div className="roll-result-container">
                {preRollResults.length > 0 && (
                    <div className="roll-results pre-roll-results">
                        <h3>PRE Roll Results</h3>
                        <div className="batting-stats">
                            <h3>Calculated Stats</h3>
                            <p>Batting Average: {preBattingStats.average}</p>
                            <p>On-Base Percentage: {preBattingStats.obp}</p>
                            <p>Slugging Percentage: {preBattingStats.slugging}</p>
                            <p>OPS: {preBattingStats.ops}</p>
                            <p>H1 Count: {preBattingStats.h1}</p>
                            <p>H2 Count: {preBattingStats.h2}</p>
                            <p>H3 Count: {preBattingStats.h3}</p>
                            <p>HR Count: {preBattingStats.hr}</p>
                            <p>SO Count: {preBattingStats.so}</p>
                            <p>OO Count: {preBattingStats.oo}</p>
                            <p>BB Count: {preBattingStats.bb}</p>
                        </div>
                    </div>
                )}
                {rollResults.length > 0 && (
                    <div className="roll-results post-roll-results">
                        <h3>Roll Results</h3>
                        <div className="batting-stats">
                            <h3>Calculated Stats</h3>
                            <p>Batting Average: {battingStats.average}</p>
                            <p>On-Base Percentage: {battingStats.obp}</p>
                            <p>Slugging Percentage: {battingStats.slugging}</p>
                            <p>OPS: {battingStats.ops}</p>
                            <p>H1 Count: {battingStats.h1}</p>
                            <p>H2 Count: {battingStats.h2}</p>
                            <p>H3 Count: {battingStats.h3}</p>
                            <p>HR Count: {battingStats.hr}</p>
                            <p>SO Count: {battingStats.so}</p>
                            <p>OO Count: {battingStats.oo}</p>
                            <p>BB Count: {battingStats.bb}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

DiceRollerVs.propTypes = {
    battingCard: PropTypes.shape({
        cardID: PropTypes.string.isRequired,
        playerID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        d2: PropTypes.string,
        d3: PropTypes.string,
        d4: PropTypes.string,
        d5: PropTypes.string,
        d6: PropTypes.string,
        d7: PropTypes.string,
        d8: PropTypes.string,
        d9: PropTypes.string,
        d10: PropTypes.string,
        d11: PropTypes.string,
        d12: PropTypes.string,
        xd2: PropTypes.string,
        xd3: PropTypes.string,
        xd4: PropTypes.string,
        xd5: PropTypes.string,
        xd6: PropTypes.string,
        xd7: PropTypes.string,
        xd8: PropTypes.string,
        xd9: PropTypes.string,
        xd10: PropTypes.string,
        xd11: PropTypes.string,
        xd12: PropTypes.string,
        pd2: PropTypes.string,
        pd3: PropTypes.string,
        pd4: PropTypes.string,
        pd5: PropTypes.string,
        pd6: PropTypes.string,
        pd7: PropTypes.string,
        pd8: PropTypes.string,
        pd9: PropTypes.string,
        pd10: PropTypes.string,
        pd11: PropTypes.string,
        pd12: PropTypes.string,
        Batting: PropTypes.shape({
            yearID: PropTypes.number,
            G: PropTypes.number,
            AB: PropTypes.number,
            R: PropTypes.number,
            H: PropTypes.number,
            '2B': PropTypes.number,
            '3B': PropTypes.number,
            HR: PropTypes.number,
            RBI: PropTypes.number,
            SB: PropTypes.number,
            CS: PropTypes.number,
            BB: PropTypes.number,
            SO: PropTypes.number,
            HBP: PropTypes.number,
            SF: PropTypes.number,
        }),
        Pitching: PropTypes.shape({
            yearID: PropTypes.number,
            G: PropTypes.number,
            GS: PropTypes.number,
            W: PropTypes.number,
            L: PropTypes.number,
            CG: PropTypes.number,
            SHO: PropTypes.number,
            SV: PropTypes.number,
            IP: PropTypes.number,
            H: PropTypes.number,
            ER: PropTypes.number,
            HR: PropTypes.number,
            BB: PropTypes.number,
            SO: PropTypes.number,
        }),
    }).isRequired,
    pitchingCard: PropTypes.shape({
        cardID: PropTypes.string.isRequired,
        playerID: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        d2: PropTypes.string,
        d3: PropTypes.string,
        d4: PropTypes.string,
        d5: PropTypes.string,
        d6: PropTypes.string,
        d7: PropTypes.string,
        d8: PropTypes.string,
        d9: PropTypes.string,
        d10: PropTypes.string,
        d11: PropTypes.string,
        d12: PropTypes.string,
        xd2: PropTypes.string,
        xd3: PropTypes.string,
        xd4: PropTypes.string,
        xd5: PropTypes.string,
        xd6: PropTypes.string,
        xd7: PropTypes.string,
        xd8: PropTypes.string,
        xd9: PropTypes.string,
        xd10: PropTypes.string,
        xd11: PropTypes.string,
        xd12: PropTypes.string,
        pd2: PropTypes.string,
        pd3: PropTypes.string,
        pd4: PropTypes.string,
        pd5: PropTypes.string,
        pd6: PropTypes.string,
        pd7: PropTypes.string,
        pd8: PropTypes.string,
        pd9: PropTypes.string,
        pd10: PropTypes.string,
        pd11: PropTypes.string,
        pd12: PropTypes.string,
        Batting: PropTypes.shape({
            yearID: PropTypes.number,
            G: PropTypes.number,
            AB: PropTypes.number,
            R: PropTypes.number,
            H: PropTypes.number,
            '2B': PropTypes.number,
            '3B': PropTypes.number,
            HR: PropTypes.number,
            RBI: PropTypes.number,
            SB: PropTypes.number,
            CS: PropTypes.number,
            BB: PropTypes.number,
            SO: PropTypes.number,
            HBP: PropTypes.number,
            SF: PropTypes.number,
        }),
        Pitching: PropTypes.shape({
            yearID: PropTypes.number,
            G: PropTypes.number,
            GS: PropTypes.number,
            W: PropTypes.number,
            L: PropTypes.number,
            CG: PropTypes.number,
            SHO: PropTypes.number,
            SV: PropTypes.number,
            IP: PropTypes.number,
            H: PropTypes.number,
            ER: PropTypes.number,
            HR: PropTypes.number,
            BB: PropTypes.number,
            SO: PropTypes.number,
        }),
    }).isRequired,
};

export default DiceRollerVs;
