import PropTypes from 'prop-types';

export const cardShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    positions: PropTypes.string,
    speed: PropTypes.number,
    artistID: PropTypes.number,
    customImage: PropTypes.string,
    d2: PropTypes.string,
    d3: PropTypes.string,
    d4: PropTypes.string,
    d5: PropTypes.string,
    d6: PropTypes.string,
    d7: PropTypes.string,
    d8: PropTypes.string,
    d9: PropTypes.string,
    d10: PropTypes.string,
    d11: PropTypes.string,
    d12: PropTypes.string,
    xd2: PropTypes.string,
    xd3: PropTypes.string,
    xd4: PropTypes.string,
    xd5: PropTypes.string,
    xd6: PropTypes.string,
    xd7: PropTypes.string,
    xd8: PropTypes.string,
    xd9: PropTypes.string,
    xd10: PropTypes.string,
    xd11: PropTypes.string,
    xd12: PropTypes.string,
    pd2: PropTypes.string,
    pd3: PropTypes.string,
    pd4: PropTypes.string,
    pd5: PropTypes.string,
    pd6: PropTypes.string,
    pd7: PropTypes.string,
    pd8: PropTypes.string,
    pd9: PropTypes.string,
    pd10: PropTypes.string,
    pd11: PropTypes.string,
    pd12: PropTypes.string,
});

export const cardPropTypes = {
    card: cardShape.isRequired,
    flip: PropTypes.bool,
    flipKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Allows both number and string
    diceResultH: PropTypes.number,
    diceResultX: PropTypes.number,
    diceResultP: PropTypes.number,
    showNewCards: PropTypes.bool,
};
