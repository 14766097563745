export const TEAMS = {
    ANA: 'California',
    ARI: 'Arizona',
    ATL: 'Atlanta',
    ALT: 'Atlanta',
    BAL: 'Baltimore',
    BL1: 'Baltimore Canaries',
    BL2: 'Baltimore',
    BL3: 'Baltimore',
    BLA: 'Baltimore',
    BLF: 'Baltimore Terrapins',
    BLN: 'Baltimore',
    BLU: 'Baltimore  Monumentals',
    BL4: 'Baltimore  Marylands',
    BOS: 'Boston',
    BS1: 'Boston',
    BS2: 'Boston',
    BR1: 'Brooklyn Eckfords',
    BR2: 'Brooklyn Atlantics',
    BR3: 'Brooklyn',
    BR4: 'Brooklyn Gladiators',
    BRF: 'Brooklyn Tip-Tops',
    BRP: "Brooklyn Ward's Wonders",
    BRO: 'Brooklyn Grooms',
    BUF: 'Buffalo Buffeds',
    BFN: 'Buffalo Bisons',
    BFP: 'Buffalo Bisons',
    BSP: 'Boston ',
    BSN: 'Boston ',
    CAL: 'California',
    CH1: 'Chicago',
    CH2: 'Chicago',
    CHA: 'Chicago American',
    CHF: 'Chicago  Chi-Feds',
    CHP: 'Chicago',
    CHN: 'Chicago National',
    CHU: 'Chicago/Pittsburgh',
    CIN: 'Cincinnati',
    CN1: 'Cincinnati Stars',
    CN2: 'Cincinnati',
    CN3: "Cincinnati Kelly's Killers",
    CLE: 'Cleveland',
    CL1: 'Cleveland Forest Citys',
    CL2: 'Cleveland Blues',
    CL3: 'Cleveland Blues',
    CL4: 'Cleveland Spiders',
    CL5: 'Columbus Buckeyes',
    CL6: 'Columbus Solons',
    CLP: 'Cleveland Infants',
    COL: 'Colorado',
    DET: 'Detroit',
    DTN: 'Detroit Wolverines',
    ELI: 'Elizabeth Resolutes',
    FLO: 'Sunshine State',
    FW1: 'Fort Wayne Kekiongas',
    HAR: 'Hartford Dark Blues',
    HOU: 'Houston',
    HR1: 'Hartford Dark Blues',
    IN1: 'Indianapolis Blues',
    IN2: 'Indianapolis Hoosiers',
    IN3: 'Indianapolis Hoosiers',
    IND: 'Indianapolis Hoosiers',
    KCA: 'Kansas City',
    KCN: 'Kansas City Cowboys',
    KC1: 'Kansas City',
    KC2: 'Kansas City Cowboys',
    KCF: 'Kansas City Packers',
    KCU: 'Kansas City Cowboys',
    KEO: 'Keokuk Westerns',
    LAN: 'Los Angeles National',
    LAA: 'Los Angeles American',
    LS1: 'Louisville Grays',
    LS2: 'Louisville Colonels',
    LS3: 'Louisville Colonels',
    MIA: 'Miami',
    MIL: 'Milwaukee',
    MLA: 'Milwaukee',
    ML1: 'Milwaukee',
    ML2: 'Milwaukee Grays',
    ML3: 'Milwaukee',
    ML4: 'Milwaukee',
    MLU: 'Milwaukee',
    MID: 'Middletown Mansfields',
    MIN: 'Minnesota',
    MON: 'Montreal',
    NH1: 'New Haven Elm Citys',
    NEW: 'Newark Pepper',
    NYA: 'New York',
    NYN: 'New York',
    NY1: 'New York',
    NY2: 'New York Mutuals',
    NY3: 'New York Mutuals',
    NY4: 'New York Metropolitans',
    NYP: 'New York',
    OAK: 'Oakland',
    PHU: 'Philadelphia Keystones',
    PHA: 'Philadelphia',
    PH1: 'Philadelphia',
    PH2: 'Philadelphia Whites',
    PH3: 'Philadelphia',
    PH4: 'Philadelphia',
    PHI: 'Philadelphia',
    PHN: 'Philadelphia',
    PHP: 'Philadelphia',
    PIT: 'Pittsburgh',
    PT1: 'Allegheny City',
    PTF: 'Pittsburgh Rebels',
    PTP: 'Pittsburgh Burghers',
    PRO: 'Providence Grays',
    RC1: 'Rockford Forest Citys',
    RC2: 'Rochester Broncos',
    RIC: 'Richmond Virginians',
    SDN: 'San Diego',
    SFN: 'San Francisco',
    SEA: 'Seattle',
    SE1: 'Seattle Pilots',
    SLA: 'St. Louis Browns',
    SLF: 'St. Louis Terriers',
    SL1: 'St. Louis',
    SL2: 'St. Louis Brown Stockings',
    SL3: 'St. Louis Brown Stockings',
    SL4: 'St. Louis Browns',
    SL5: 'St. Louis Maroons',
    SLN: 'St. Louis',
    SPU: 'St. Paul White Caps',
    SR1: 'Syracuse Stars',
    SR2: 'Syracuse Stars',
    TEX: 'Texas',
    TBA: 'Tampa Bay',
    TL1: 'Toledo Blue Stockings',
    TL2: 'Toledo Maumees',
    TOR: 'Toronto',
    TRO: 'Troy Haymakers',
    WAS: 'Washington',
    WIL: 'Wilmington Quicksteps',
    WOR: 'Worcester Ruby Legs',
    WS1: 'Washington',
    WS2: 'Washington Senators',
    WS3: 'Washington Olympics',
    WS4: 'Washington',
    WS5: 'Washington Blue Legs',
    WS6: 'Washington',
    WS7: 'Washington',
    WS8: 'Washington',
    WS9: 'Washington Statesmen',
};

export const teamNameTranslator = teams => {
    const teamCodes = teams.split(',').map(team => team.trim());
    const translatedTeams = teamCodes.map(code => TEAMS[code] || code);
    const uniqueTeams = [...new Set(translatedTeams)];

    return uniqueTeams.join(', ');
};

export const sortTeamLineup = lineup => {
    const lineupOrder = lineup
        .filter(player => player.position !== 'Bench' && player.position !== 'SP' && player.position !== 'RP')
        .sort((a, b) => a.lineupOrder - b.lineupOrder);

    const benchSpots = lineup.filter(player => player.position === 'Bench');
    const sp = lineup.filter(player => player.position === 'SP');
    const rp = lineup.filter(player => player.position === 'RP');

    return [...lineupOrder, ...benchSpots, ...sp, ...rp];
};

/* 

BR1,BR2,BR3,BR4,BRF,BRP,BRO,LAN
CIN,CN1,CN2,CN3 
WS2,MIN
SEA
BAL,BL2,BL3,BLA,BLN,BLU,BL4,SLA,MLU,MLA
NYA, 
*/
