import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSeries, createSeries, updateSeries } from '../../api/series';

const SeriesManagementPage = () => {
    const { currentUser } = useAuth();
    const [seriesList, setSeriesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [form, setForm] = useState({
        name: '',
        seriesKey: '',
        year: '',
        cost: '',
        minRating: '',
        teamsIncluded: '',
        purchaseLimit: '',
        isActive: false,
        numberOfCards: 6,
    });

    const fetchSeries = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchAllSeries();
            const sortedData = data.sort((a, b) => a.year - b.year);
            setSeriesList(sortedData);
        } catch (error) {
            setError('Error fetching series');
            toast.error('Error fetching series: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentUser?.isAdmin) {
            fetchSeries();
        }
    }, [currentUser]);

    const handleChange = e => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : type === 'number' ? Number(value) : value;

        setForm(prevForm => ({
            ...prevForm,
            [name]: newValue,
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setError(null);
        try {
            if (form.seriesID) {
                await updateSeries(form.seriesID, form);
            } else {
                await createSeries(form);
            }
            fetchSeries();
            setForm({
                name: '',
                seriesKey: '',
                year: '',
                cost: '',
                minRating: '',
                teamsIncluded: '',
                purchaseLimit: '',
                isActive: false,
                numberOfCards: 6,
            });
            toast.success('Series saved successfully!');
        } catch (error) {
            setError('Error saving series');
            toast.error('Error saving series: ' + error.message);
        }
    };

    const handleEdit = series => {
        console.log('series', series);
        setForm(series);
    };

    if (!currentUser?.isAdmin) {
        return <p>Access denied. Only admins can manage series.</p>;
    }

    return (
        <div className="series-management-page">
            <h2>Series Management</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <label>
                    Name:
                    <input type="text" name="name" value={form.name} onChange={handleChange} required />
                </label>
                <label>
                    Series Key:
                    <input type="text" name="seriesKey" value={form.seriesKey} onChange={handleChange} required />
                </label>
                <label>
                    Year:
                    <input type="number" name="year" value={form.year} onChange={handleChange} />
                </label>
                <label>
                    Cost:
                    <input type="number" name="cost" value={form.cost} onChange={handleChange} />
                </label>
                <label>
                    minRating:
                    <input type="number" name="minRating" value={form.minRating} onChange={handleChange} />
                </label>
                <label>
                    teamsIncluded:
                    <input type="text" name="teamsIncluded" value={form.teamsIncluded} onChange={handleChange} />
                </label>
                <label>
                    Purchase Limit:
                    <input type="number" name="purchaseLimit" value={form.purchaseLimit} onChange={handleChange} />
                </label>
                <label>
                    Active:
                    <input type="checkbox" name="isActive" checked={form.isActive} onChange={handleChange} />
                </label>
                <label>
                    Number of Cards:
                    <input type="number" name="numberOfCards" value={form.numberOfCards} onChange={handleChange} />
                </label>
                <button type="submit">Save Series</button>
            </form>
            <h3>Existing Series</h3>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    {seriesList.map(series => (
                        <div key={series.seriesID} className="series-item">
                            <span>
                                {series.name} ({series.year})
                            </span>
                            <button onClick={() => handleEdit(series)}>Edit</button>
                            <Link to={`/admin/series/${series.seriesID}`}>View</Link>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SeriesManagementPage;
