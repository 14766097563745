import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CardTable from '../../components/CardTable';
import { fetchSeriesDetails, fetchSeriesCards } from '../../api/series';
import { recalculateCardRating } from '../../api/cards';
import { toast } from 'react-toastify';

const SeriesDetailPage = () => {
    const { seriesID } = useParams();
    const [series, setSeries] = useState(null);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'rating', direction: 'descending' });

    useEffect(() => {
        const fetchDetails = async () => {
            setLoading(true);
            setError(null);
            try {
                const seriesData = await fetchSeriesDetails(seriesID);
                setSeries(seriesData);

                const cardsData = await fetchSeriesCards(seriesID);
                const updatedCards = cardsData.map(card => ({
                    ...card,
                    Series: { seriesKey: seriesData.seriesKey },
                }));

                setCards(updatedCards);
            } catch (error) {
                setError('Error fetching series details or cards');
                console.error('Error fetching series details or cards:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDetails();
    }, [seriesID]);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((o, i) => (o ? o[i] : null), obj);
    };

    const sortedCards = [...cards].sort((a, b) => {
        const aValue = getNestedValue(a, sortConfig.key);
        const bValue = getNestedValue(b, sortConfig.key);

        if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const handleRescoreSeries = async () => {
        try {
            await recalculateCardRating(seriesID);
            const updatedCards = await fetchSeriesCards(seriesID);
            setCards(updatedCards);
            toast.success('Series cards rescored successfully');
        } catch (error) {
            toast.error('Error rescoring series cards');
            console.error('Error rescoring series cards:', error);
        }
    };

    return (
        <div className="series-detail-page">
            {error && <p className="error">{error}</p>}
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <h2>
                        {series?.name} ({series?.year})
                    </h2>
                    <button onClick={handleRescoreSeries}>Rescore All Cards in Series</button>
                    <CardTable cards={sortedCards} requestSort={requestSort} sortConfig={sortConfig} />
                </>
            )}
        </div>
    );
};

export default SeriesDetailPage;
