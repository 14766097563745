import { translateOuts } from '../utils/gameUtils';

export const sortLineup = lineup => {
    const sortedLineup = [...lineup].sort((a, b) => {
        if (a.lineupOrder === null) return 1;
        if (b.lineupOrder === null) return -1;
        return a.lineupOrder - b.lineupOrder;
    });
    return sortedLineup;
};

export const isUserPitching = (game, currentUser) => {
    const userParticipant = game.GameParticipants?.find(participant => participant.userID === currentUser.userID);
    const latestEvent = game && game.GameEvents && game.GameEvents[0];

    if (!userParticipant || !latestEvent) return false;

    const translatedOuts = translateOuts(latestEvent.ipOuts);
    if (userParticipant.role == 'home') {
        return translatedOuts.inningHalf == 'Top';
    } else {
        return translatedOuts.inningHalf == 'Bottom';
    }
};

export const isPositionAllowed = (playerPositions, position) => {
    const allowedPlayerPositions = playerPositions.split(',').map(pos => pos.trim());
    const isPlayerAPitcher = allowedPlayerPositions.includes('SP') || allowedPlayerPositions.includes('RP');

    if (position === 'P') {
        return isPlayerAPitcher;
    }

    if (position === 'DH') {
        return !isPlayerAPitcher;
    }

    if (['LF', 'RF', 'CF'].includes(position) && allowedPlayerPositions.includes('OF')) {
        return true;
    }

    // For other positions, check if the position is in the allowed positions
    return allowedPlayerPositions.includes(position);
};
