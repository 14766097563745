import axios from './axios';

export const fetchUserTeams = async () => {
    try {
        const response = await axios.get(`/teams/user`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user teams:', error);
        return [];
    }
};

export const createUserTeamWithLineup = async teamPayload => {
    try {
        const response = await axios.post('/teams', teamPayload);
        return response.data;
    } catch (error) {
        console.error('Error creating user team and lineup:', error);
        throw error;
    }
};

export const editUserTeamWithLineup = async teamPayload => {
    try {
        const response = await axios.put(`/teams`, teamPayload);
        return response.data;
    } catch (error) {
        console.error('Error creating user team and lineup:', error);
        throw error;
    }
};

export const fetchAllTeams = async () => {
    try {
        const response = await axios.get('/teams/all');
        return response.data;
    } catch (error) {
        console.error('Error fetching all user teams:', error);
        throw error;
    }
};

export const fetchCompTeams = async () => {
    try {
        const response = await axios.get('/teams/comp-team');
        return response.data;
    } catch (error) {
        console.error('Error fetching all comp teams:', error);
        throw error;
    }
};

export const fetchAllYearsAndTeams = async () => {
    try {
        const response = await axios.get('/teams/comp-team-years');
        return response.data;
    } catch (error) {
        console.error('Error fetching all years and teams:', error);
        throw error;
    }
};

export const buildCompTeam = async (year, teamID) => {
    try {
        const response = await axios.post('/teams/comp-team', { year, teamID });
        return response.data;
    } catch (error) {
        console.error('Error submitting year and team:', error);
        throw error;
    }
};
