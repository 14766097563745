import React from 'react';

const Modal = ({ children, onClose }) => {
    const handleOverlayClick = e => {
        if (e.target === e.currentTarget) {
            onClose(); // Close the modal if clicked outside the modal content
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    X
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
