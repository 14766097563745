import axios from './axios';

export const fetchWallet = async () => {
    try {
        const response = await axios.get('/wallet/get');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};
