import React, { createContext, useContext, useState, useEffect } from 'react';
import { loginUser, logoutUser, registerUser, fetchUserDetails } from '../api/auth';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [authLoaded, setAuthLoaded] = useState(false);

    useEffect(() => {
        const idToken = localStorage.getItem('idToken');
        if (idToken) {
            fetchUserDetails(idToken)
                .then(userDetails => {
                    const user = userDetails.user;
                    if (user) {
                        setCurrentUser(user);
                    }
                    setAuthLoaded(true);
                })
                .catch(error => {
                    console.error('Error fetching user details:', error);
                    setAuthLoaded(true);
                });
        } else {
            setAuthLoaded(true);
        }
    }, []);

    const handleLogin = async (username, password) => {
        try {
            const authResult = await loginUser(username, password);
            const { AccessToken, IdToken, RefreshToken } = authResult;

            try {
                localStorage.setItem('accessToken', AccessToken);
                localStorage.setItem('idToken', IdToken);
                localStorage.setItem('refreshToken', RefreshToken);
            } catch (error) {
                alert('LocalStorage is required to use this application. Please enable it in your browser settings.');
                throw new Error('LocalStorage is not accessible');
            }

            const userDetails = await fetchUserDetails(IdToken);
            setCurrentUser(userDetails.user);
            setAuthLoaded(true);
            toast.success('Login successful!');
        } catch (error) {
            console.error('Login failed:', error.message);
            toast.error('Login failed: ' + error.message);
        }
    };

    const handleLogout = async () => {
        try {
            await logoutUser();
            localStorage.removeItem('accessToken');
            localStorage.removeItem('idToken');
            localStorage.removeItem('refreshToken');
            setCurrentUser(null);
            setAuthLoaded(true);
            toast.success('Logout successful!');
        } catch (error) {
            console.error('Logout failed:', error.message);
            toast.error('Logout failed: ' + error.message);
        }
    };

    const handleRegister = async (username, email, password) => {
        try {
            const data = await registerUser(username, email, password);
            toast.success('Registration successful!');
            return data;
        } catch (error) {
            toast.error('Registration failed: ' + error.message);
            throw error;
        }
    };

    const value = {
        currentUser,
        setCurrentUser,
        authLoaded,
        login: handleLogin,
        logout: handleLogout,
        register: handleRegister,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
