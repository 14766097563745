import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { updateGameSpeed } from '../api/user';
import GLOBALS from '../config/globals';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AccountPage = () => {
    const { currentUser, setCurrentUser } = useAuth();
    const [gameSpeed, setGameSpeed] = useState(currentUser?.gameSpeed || GLOBALS.GAME_SPEED.NORMAL.name);

    useEffect(() => {
        // If gameSpeed is already in currentUser, set it to the initial state
        if (currentUser?.gameSpeed) {
            setGameSpeed(currentUser.gameSpeed);
        }
    }, [currentUser]);

    const handleGameSpeedChange = async e => {
        const selectedSpeed = e.target.value;
        setGameSpeed(selectedSpeed);

        try {
            // Use the helper function to update the game speed on the backend
            await updateGameSpeed(selectedSpeed);

            // Update the game speed in the context
            setCurrentUser({ ...currentUser, gameSpeed: selectedSpeed });
            toast.success('Game speed updated successfully!');
        } catch (err) {
            console.error('Error updating game speed:', err);
            toast.error('Failed to update game speed: ' + err.message);
        }
    };

    return (
        <div className="container">
            <h1 className="header">Account</h1>
            <p className="paragraph">
                Settings:
                <br />
                Game Animation Speed:
                <select value={gameSpeed} onChange={handleGameSpeedChange}>
                    <option value={GLOBALS.GAME_SPEED.NORMAL.name}>Normal</option>
                    <option value={GLOBALS.GAME_SPEED.FAST.name}>Fast</option>
                    <option value={GLOBALS.GAME_SPEED.FASTER.name}>Fastest</option>
                    <option value={GLOBALS.GAME_SPEED.INSTANT.name}>Instant</option>
                </select>
            </p>
        </div>
    );
};

export default AccountPage;
