import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import ContactPage from '../pages/ContactPage';
import ArtistPage from '../pages/ArtistPage';
import FAQPage from '../pages/FAQPage';
import CardCustomPage from '../pages/CardCustomPage';
import WalletPage from '../pages/WalletPage';
import AccountPage from '../pages/AccountPage';
import PacksBuyPage from '../pages/packs/BuyPacksPage';
import UserPacksPage from '../pages/packs/UserPacksPage';
import CardsUserPage from '../pages/cards/UserCardsPage';
import SellCardsPage from '../pages/cards/SellCardsPage';
import RegisterPage from '../pages/RegisterPage';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import SeriesManagementPage from '../pages/series/SeriesManagementPage';
import BulkCardsPage from '../pages/cards/BulkCardsPage';
import SeriesDetailsPage from '../pages/series/SeriesDetailPage';
import AllSeriesPage from '../pages/series/AllSeriesPage';
import CardDetailsPage from '../pages/cards/CardDetailsPage';
import CreateTeam from '../pages/team/CreateTeamPage';
import ViewTeams from '../pages/team/ViewTeamsPage';
import ViewGames from '../pages/games/ViewGamesPage';
import CreateGame from '../pages/games/CreateGamePage';
import PlayGame from '../pages/games/PlayGamePage';
import SummaryGame from '../pages/games/GameSummaryPage';
import AdminRollPage from '../pages/admin/AdminDiceRollPage';
import AdminUsersPage from '../pages/admin/AdminUsersPage';
import LeaderboardPage from '../pages/leaderboard/LeaderboardPage';
import CreateCompTeamPage from '../pages/team/CreateCompTeamPage';
import ViewCompTeamsPage from '../pages/team/ViewCompTeamsPage';
import ViewRewardsPage from '../pages/rewards/ViewRewardsPage';
import CreateRewardsPage from '../pages/rewards/CreateRewardsPage';
import CreateCampaignPage from '../pages/campaigns/CreateCampaignPage';
import ViewCampaignsPage from '../pages/campaigns/ViewCampaignsPage';
import CreateCampaignContestPage from '../pages/campaigns/CreateCampaignContestPage';
import CampaignContestDetailPage from '../pages/campaigns/CampaignContestDetailPage';
import CampaignDetailPage from '../pages/campaigns/CampaignDetailPage';
import PlayCampaignContestPage from '../pages/campaigns/PlayCampaignContestPage';

const routes = [
    { path: '/', component: HomePage },
    { path: '/about', component: AboutPage },
    { path: '/contact', component: ContactPage },
    { path: '/artists', component: ArtistPage },
    { path: '/faq', component: FAQPage },
    { path: '/cardCustom', component: CardCustomPage, private: true },
    { path: '/wallet', component: WalletPage, private: true },
    { path: '/account', component: AccountPage, private: true },
    { path: '/login', component: LoginPage },
    { path: '/logout', component: LogoutPage },
    { path: '/leaderboard', component: LeaderboardPage },
    { path: '/register', component: RegisterPage },
    { path: '/packs/buy', component: PacksBuyPage, private: true },
    { path: '/packs/user', component: UserPacksPage, private: true },
    { path: '/cards/user', component: CardsUserPage, private: true },
    { path: '/cards/sell', component: SellCardsPage, private: true },
    { path: '/team/create', component: CreateTeam, private: true },
    { path: '/team/edit', component: CreateTeam, private: true },
    { path: '/team/user', component: ViewTeams, private: true },
    { path: '/games/user', component: ViewGames, private: true },
    { path: '/rewards', component: ViewRewardsPage, admin: true },
    { path: '/rewards/create', component: CreateRewardsPage, admin: true },
    { path: '/team/comp-team', component: CreateCompTeamPage, admin: true },
    { path: '/team/view-comp-teams', component: ViewCompTeamsPage, admin: true },
    { path: '/admin/roll-page', component: AdminRollPage, admin: true },
    { path: '/admin/series-management', component: SeriesManagementPage, admin: true },
    { path: '/admin/cards/bulk', component: BulkCardsPage, admin: true },
    { path: '/admin/series', component: AllSeriesPage, admin: true },
    { path: '/admin/users', component: AdminUsersPage, admin: true },
    { path: '/admin/series/:seriesID', component: SeriesDetailsPage, admin: true },
    { path: '/campaigns/create', component: CreateCampaignPage, admin: true },
    { path: '/campaigns', component: ViewCampaignsPage },
    { path: '/campaign-contests/create', component: CreateCampaignContestPage, admin: true },
    { path: '/campaign-contests/:campaignContestID', component: CampaignContestDetailPage },
    { path: '/campaign-contests/:userCampaignContestID/play', component: PlayCampaignContestPage },
    { path: '/campaigns/:campaignID', component: CampaignDetailPage },
    { path: '/cards/:cardID', component: CardDetailsPage },
    { path: '/game/create', component: CreateGame, private: true },
    { path: '/game/create/:campaignContestID', component: CreateGame, private: true },
    { path: '/game/:gameID/play', component: PlayGame, private: true },
    { path: '/game/:gameID/summary', component: SummaryGame, private: true },
];

export default routes;
