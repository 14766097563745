import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useParams, Link } from 'react-router-dom';
import { fetchCampaignContestByID, updateCampaignContest } from '../../api/campaigns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CampaignContestDetailPage = () => {
    const { currentUser } = useAuth();
    const { campaignContestID } = useParams();
    const [campaignContest, setCampaignContest] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        campaignID: '',
        name: '',
        desc: '',
        userTeamID: '',
        rewardID: '',
        numOfGames: '',
    });

    useEffect(() => {
        const fetchCampaignContestData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await fetchCampaignContestByID(campaignContestID);
                setCampaignContest(data);
                setFormData({
                    campaignID: data.campaignID,
                    name: data.name,
                    desc: data.desc,
                    userTeamID: data.userTeamID,
                    rewardID: data.rewardID,
                    numOfGames: data.numOfGames,
                });
                //('Campaign Contest fetched successfully!');
            } catch (error) {
                setError('Error fetching campaign contest');
                toast.error('Error fetching campaign contest: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCampaignContestData();
    }, [campaignContestID]);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        setLoading(true);
        setError(null);
        try {
            const updatedContest = await updateCampaignContest(campaignContestID, {
                ...formData,
                campaignID: formData.campaignID,
                userTeamID: formData.userTeamID,
                rewardID: formData.rewardID ? formData.rewardID : null,
                numOfGames: formData.numOfGames ? parseInt(formData.numOfGames, 10) : 7,
            });
            setCampaignContest(updatedContest);
            setEditMode(false);
            toast.success('Campaign Contest updated successfully!');
        } catch (error) {
            setError('Error updating campaign contest');
            toast.error('Error updating campaign contest: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    if (!campaignContest) {
        return <div>No campaign contest found.</div>;
    }

    return (
        <div className="campaign-contest-detail-page">
            <h2>Campaign Contest Details</h2>
            {editMode ? (
                <div>
                    <div>
                        <label>Campaign ID:</label>
                        <input type="text" name="campaignID" value={formData.campaignID} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label>Name:</label>
                        <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label>Description:</label>
                        <textarea name="desc" value={formData.desc} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label>User Team ID:</label>
                        <input type="text" name="userTeamID" value={formData.userTeamID} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label>Reward ID:</label>
                        <input type="text" name="rewardID" value={formData.rewardID} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label>Number of Games:</label>
                        <input type="text" name="numOfGames" value={formData.numOfGames} onChange={handleInputChange} />
                    </div>
                    <button onClick={handleSave} disabled={loading}>
                        {loading ? 'Saving...' : 'Save'}
                    </button>
                </div>
            ) : (
                <div>
                    <div>
                        <b>Campaign ID:</b> {campaignContest.campaignID}
                    </div>
                    <div>
                        <b>Name:</b> {campaignContest.name}
                    </div>
                    <div>
                        <b>Description:</b> {campaignContest.desc}
                    </div>
                    <div>
                        <b>Team:</b> {campaignContest.UserTeam?.name || 'N/A'}
                    </div>
                    <div>
                        <b>Reward:</b> {campaignContest.rewardID}
                    </div>
                    <div>
                        <b>Number of Games:</b> {campaignContest.numOfGames}
                    </div>
                    {currentUser && currentUser.isAdmin && <button onClick={() => setEditMode(true)}>Edit</button>}
                </div>
            )}
            <div>
                <Link to={`/campaigns/${campaignContest.campaignID}`}>Back to Campaign</Link>
            </div>
        </div>
    );
};

export default CampaignContestDetailPage;
