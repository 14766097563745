import { toast } from 'react-toastify';

let toastId = null;

//To prevent dupe messages
//this still doesn't work, it just has one console.log
export const toastSuccess = message => {
    //console.log('toastId', toastId);
    if (!toast.isActive(toastId)) {
        toastId = toast.success(message, { toastId: message });
    }
};

export const toastError = message => {
    if (!toast.isActive(toastId)) {
        toastId = toast.error(message, { toastId: message });
    }
};
