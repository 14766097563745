// src/pages/ContactPage.js
import React from 'react';

const ContactPage = () => {
    return (
        <div className="container">
            <h1 className="header">How do you contact us?</h1>
            <p className="paragraph">
                Thank you for visiting our site. We offer a range of products and services designed to help you achieve
                your goals.
            </p>
        </div>
    );
};

export default ContactPage;
