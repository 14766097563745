import axios from './axios';

export const createCampaign = async payload => {
    try {
        const response = await axios.post('/campaign/create', payload);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const updateCampaign = async (campaignID, payload) => {
    try {
        const response = await axios.put(`/campaign/${campaignID}`, payload);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchAllCampaigns = async () => {
    try {
        const response = await axios.get('/campaign');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchPublicCampaignByID = async campaignID => {
    const response = await axios.get(`/campaign/${campaignID}`);
    return response.data;
};

export const fetchUserCampaignContests = async campaignID => {
    const response = await axios.get(`/campaign/${campaignID}/user`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Assuming the JWT token is stored in localStorage
        },
    });
    return response.data;
};

export const createCampaignContest = async payload => {
    try {
        const response = await axios.post('/campaignContest/create', payload);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchAllCampaignContests = async () => {
    try {
        const response = await axios.get('/campaignContest');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchCampaignContestByID = async campaignContestID => {
    try {
        const response = await axios.get(`/campaignContest/${campaignContestID}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const updateCampaignContest = async (campaignContestID, payload) => {
    try {
        const response = await axios.put(`/campaignContest/${campaignContestID}`, payload);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchUserCampaignContestByID = async userCampaignContestID => {
    try {
        const response = await axios.get(`/userCampaignContest/${userCampaignContestID}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const createUserCampaignContest = async campaignContestID => {
    try {
        const response = await axios.post('/userCampaignContest/create', { campaignContestID });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const resignFromCampaignContest = async userCampaignContestID => {
    try {
        const response = await axios.post('/userCampaignContest/resign', { userCampaignContestID });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};
