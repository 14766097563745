import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const diceFaces = [1, 2, 3, 4, 5, 6];

const getTwoDice = result => {
    const options = [];
    for (let i = 1; i <= 6; i++) {
        for (let j = 1; j <= 6; j++) {
            if (i + j === result) {
                options.push([i, j]);
            }
        }
    }
    return options[Math.floor(Math.random() * options.length)];
};

const DiceAnimation = ({
    diceResultH,
    diceResultX,
    diceResultP,
    textH,
    textP,
    strikeThroughH,
    strikeThroughP,
    gameSpeed = 1,
}) => {
    const [rollingH, setRollingH] = useState([null, null]);
    const [rollingX, setRollingX] = useState([null, null]);
    const [rollingP, setRollingP] = useState([null, null]);
    const [showTextH, setShowTextH] = useState(false);
    const [showTextP, setShowTextP] = useState(false);

    useEffect(() => {
        const rollDice = (setRolling, result, setShowText) => {
            let counter = 0;
            const interval = setInterval(() => {
                setRolling([
                    diceFaces[Math.floor(Math.random() * diceFaces.length)],
                    diceFaces[Math.floor(Math.random() * diceFaces.length)],
                ]);
                counter++;
                if (counter > Math.round(7 * gameSpeed)) {
                    clearInterval(interval);
                    setRolling(getTwoDice(result));
                    setShowText(true);
                }
            }, 100);
        };

        if (diceResultH) {
            rollDice(setRollingH, diceResultH, setShowTextH);
        }

        if (diceResultX) {
            rollDice(setRollingX, diceResultX, () => {});
        }

        if (diceResultP) {
            rollDice(setRollingP, diceResultP, setShowTextP);
        }
    }, [diceResultH, diceResultX, diceResultP]);

    const strikeThroughStyle = {
        textDecoration: 'line-through',
    };

    const renderDice = (dice, className) => (
        <>
            <div className={`dice-face ${className}`}>{dice[0]}</div>
            <div className={`dice-face ${className}`}>{dice[1]}</div>
        </>
    );

    return (
        <div className="dice-animation">
            {rollingH[0] !== null && (
                <>
                    <div className="dice-roll">
                        {renderDice(rollingH)}{' '}
                        {showTextH && textH && (
                            <span className="highlight" style={strikeThroughH ? strikeThroughStyle : {}}>
                                ({textH})
                            </span>
                        )}
                    </div>
                    {rollingX[0] !== null && <div className="dice-roll">{renderDice(rollingX, 'xbh')}</div>}
                </>
            )}
            {rollingP[0] !== null && (
                <div className="dice-roll">
                    {renderDice(rollingP, 'pitching')}
                    {showTextP && textP && (
                        <span className="highlight" style={strikeThroughP ? strikeThroughStyle : {}}>
                            ({textP})
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

DiceAnimation.propTypes = {
    diceResultH: PropTypes.number,
    diceResultX: PropTypes.number,
    diceResultP: PropTypes.number,
    textH: PropTypes.string,
    textP: PropTypes.string,
    strikeThroughH: PropTypes.bool,
    strikeThroughP: PropTypes.bool,
    gameSpeed: PropTypes.number,
};

export default DiceAnimation;
