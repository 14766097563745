// src/utils/gameUtils.js
import GLOBALS from '../config/globals';

export const translateOuts = (ipOuts, isFinal = false) => {
    const inningOuts = ipOuts % 6;
    const halfInningOuts = inningOuts % 3;
    const inningPart = inningOuts < 3 ? GLOBALS.TOP_OF_INNING : GLOBALS.BOTTOM_OF_INNING;
    const inning = Math.floor(ipOuts / 6) + 1;

    const status = {
        complete: `${inningPart} of ${inning},  ${halfInningOuts} outs.`,
        simple: `${inningPart} of ${inning}`,
        inning,
        outs: halfInningOuts,
        inningHalf: inningPart,
    };

    if (isFinal) {
        status.complete = 'Final';
        status.simple = 'Final';
    }

    return status;
};

export const resultTypes = {
    SO: 'so',
    OO: 'oo',
    BB: 'bb',
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    HR: 'hr',
    AB: 'ab',
};
const resultTypeValues = Object.values(resultTypes);
export const isResultTypePlateAppearance = resultType => {
    return resultTypeValues.includes(resultType);
};

export const resultMap = {
    SO: 'Strikeout',
    OO: 'Out',
    BB: 'Walk',
    H1: 'Single',
    H2: 'Double',
    H3: 'Triple',
    HR: 'HOME RUN',
};

export const translateEvent = event => {
    return resultMap[(event || '').toUpperCase()];
};

const pitchRollMap = {
    h9: 'Hits',
    hr9: 'Power',
    bb9: 'Walks',
    k9: 'K`s',
};

export const translatePitchRoll = roll => {
    if (!roll) return roll;

    const rollBase = roll.slice(0, -2); // Get the base part (e.g., 'h9', 'hr9')
    const modifier = roll.slice(-1); // Get the last character (either 'l' or 'h')
    const action = modifier === 'l' ? '▼' : '▲';

    const translatedBase = pitchRollMap[rollBase.toLowerCase()];
    if (!translatedBase) return roll;

    return `${action} ${translatedBase}`;
};

export const findSub = (gameEventID, game) => {
    //loop over game.GamePerticipants.GameLineups
    //if you find an added & removed, then create a sub
    //TODO: if you sup on the first play of the game, it shows all players added in the starting lineup
    const addedPlayers = [];
    const removedPlayers = [];
    game.GameParticipants.forEach(gp => {
        gp.GameLineups.forEach(gl => {
            if (gl.gameEventIDEntered == gameEventID) {
                addedPlayers.push(gl.Card.name);
            }
            if (gl.gameEventIDExited == gameEventID) {
                removedPlayers.push(gl.Card.name);
            }
        });
    });

    if (addedPlayers.length && removedPlayers.length) {
        //only return this if there is BOTH added and removed
        const subAddedText = addedPlayers.map(name => `${name} entered the game. `).join('');
        const subRemovedText = removedPlayers.map(name => `${name} left the game. `).join('');
        return subAddedText + subRemovedText;
    }
    return null;
};

const isMultipleRunners = gameEvent => {
    return !!(
        (gameEvent.firstBaseRunnerLineup && gameEvent.secondBaseRunnerLineup) ||
        (gameEvent.firstBaseRunnerLineup && gameEvent.thirdBaseRunnerLineup) ||
        (gameEvent.secondBaseRunnerLineup && gameEvent.thirdBaseRunnerLineup)
    );
};

const isRunner = gameEvent => {
    return !!(gameEvent.firstBaseRunnerLineup || gameEvent.secondBaseRunnerLineup || gameEvent.thirdBaseRunnerLineup);
};

export const isMidEventAdvanceRunners = (gameEvent, isUserPitching) => {
    if (isUserPitching) return false;
    console.log(gameEvent.resultType);
    if (GLOBALS.OO == gameEvent.resultType) {
        console.log(gameEvent.ipOuts % 3, !gameEvent.managerAction, isRunner(gameEvent));
        return gameEvent.ipOuts % 3 && !gameEvent.managerAction && isRunner(gameEvent);
    }
    return (
        GLOBALS.MID_EVENTS.includes(gameEvent.resultType) && !gameEvent.managerAction && isMultipleRunners(gameEvent)
    );
};
