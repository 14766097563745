import axios from './axios';

export const fetchAllSeries = async () => {
    try {
        const response = await axios.get('/series');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchSeriesDetails = async seriesID => {
    try {
        const response = await axios.get(`/series/${seriesID}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const fetchSeriesCards = async seriesID => {
    try {
        const response = await axios.get(`/series/${seriesID}/cards`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const createSeries = async seriesData => {
    try {
        const response = await axios.post('/series', seriesData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const updateSeries = async (seriesID, seriesData) => {
    try {
        const response = await axios.put(`/series/${seriesID}`, seriesData);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const deleteSeries = async seriesID => {
    try {
        const response = await axios.delete(`/series/${seriesID}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};
