import axios from './axios';
import GLOBALS from '../config/globals';

export const fetchGames = async () => {
    try {
        const response = await axios.get('/games');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const createGame = async payload => {
    try {
        const response = await axios.post('/games', payload);
        return response.data;
    } catch (error) {
        console.error('Error creating game:', error);
        throw error;
    }
};

export const createCampaignGame = async payload => {
    try {
        const response = await axios.post('/games/campaign', payload);
        return response.data;
    } catch (error) {
        console.error('Error creating game:', error);
        throw error;
    }
};
export const fetchUserGames = async () => {
    try {
        const response = await axios.get(`/games/user/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user games:', error);
        throw error;
    }
};
export const fetchGameSummary = async gameID => {
    const response = await axios.get(`/games/${gameID}/summary`);
    return response.data;
};

export const fetchGameStatus = async gameID => {
    const response = await axios.get(`/games/${gameID}`);
    return response.data;
};

export const rollGameDice = async gameID => {
    const response = await axios.get(`/games/${gameID}/roll`);
    return response.data;
};

export const managedEvent = async ({ gameID, event, runnersAdvancing }) => {
    const response = await axios.post(`/games/${gameID}/manageEvent`, { event, runnersAdvancing });
    return response.data;
};

export const subPlayer = async subData => {
    const response = await axios.post(`/games/${subData.gameID}/sub`, subData);
    return response.data;
};

export const submitMidEventChoice = async (runnersAdvancing, gameID) => {
    const response = await axios.post(`/games/${gameID}/manageEvent`, {
        event: GLOBALS.MANAGED_EVENTS.EXTRA_BASE.name,
        runnersAdvancing,
    });
    return response.data;
};
