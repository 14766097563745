import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGameSummary } from '../../api/games';
import { translateOuts, resultTypes, findSub, isResultTypePlateAppearance } from '../../utils/gameUtils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

const GameSummaryPage = () => {
    const { gameID } = useParams();
    const [game, setGame] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [boxScoreExpanded, setBoxScoreExpanded] = useState(true);
    const [eventsExpanded, setEventsExpanded] = useState(false);

    useEffect(() => {
        const getGameStatus = async () => {
            setLoading(true);
            setError(null);
            try {
                const gameData = await fetchGameSummary(gameID);
                setGame(gameData);
            } catch (error) {
                setError('Error fetching game status: ' + error.message);
                toast.error('Error fetching game status: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        getGameStatus();
    }, [gameID]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    const homeParticipant = game.GameParticipants?.find(participant => participant.role === 'home');
    const awayParticipant = game.GameParticipants?.find(participant => participant.role === 'away');
    const latestEvent = game.GameEvents?.[0];

    const sortLineup = lineup => {
        const sortedLineup = [...lineup].sort((a, b) => {
            if (a.lineupOrder === null) return 1;
            if (b.lineupOrder === null) return -1;
            return a.lineupOrder - b.lineupOrder;
        });
        return sortedLineup;
    };

    const toggleBoxScore = () => setBoxScoreExpanded(!boxScoreExpanded);
    const toggleEvents = () => setEventsExpanded(!eventsExpanded);

    const generateBoxScore = game => {
        const playerStats = {};

        game.GameEvents.forEach(event => {
            const { batterLineup, pitcherLineup, resultType } = event;
            const isPlateAppearance = isResultTypePlateAppearance(resultType);
            //TODO: I think we can do this by lineupID?
            if (batterLineup) {
                const batterID = batterLineup.gameLineupID;

                if (!playerStats[batterID]) {
                    playerStats[batterID] = {
                        plateAppearances: 0,
                        ab: 0,
                        h1: 0,
                        h2: 0,
                        h3: 0,
                        rbi: 0,
                        bb: 0,
                        so: 0,
                        hr: 0,
                        r: 0,
                        sb: 0,
                        cs: 0,
                    };
                }
                if (isPlateAppearance) {
                    playerStats[batterID].ab += 1;
                    playerStats[batterID].plateAppearances += 1;
                    if (resultType === resultTypes.H1) playerStats[batterID].h1 += 1;
                    if (resultType === resultTypes.H2) playerStats[batterID].h2 += 1;
                    if (resultType === resultTypes.H3) playerStats[batterID].h3 += 1;
                    if (resultType === resultTypes.HR) playerStats[batterID].hr += 1;
                    if (resultType === resultTypes.BB) {
                        playerStats[batterID].bb += 1;
                        playerStats[batterID].ab -= 1;
                    }
                    if (resultType === resultTypes.SO) playerStats[batterID].so += 1;
                    if (resultType === resultTypes.OO) playerStats[batterID].oo += 1;

                    if (event.rbi) playerStats[batterID].rbi += event.rbi;
                }
                //TODO runs and steals don't need a PA
                if (event.runs) playerStats[batterID].r += event.runs;
                if (event.sb) playerStats[batterID].sb += event.sb;
                if (event.cs) playerStats[batterID].cs += event.cs;
            }

            if (pitcherLineup) {
                const pitcherID = pitcherLineup.gameLineupID;

                if (!playerStats[pitcherID]) {
                    playerStats[pitcherID] = {
                        plateAppearances: 0,
                        ipOuts: 0,
                        ab: 0,
                        h1: 0,
                        h2: 0,
                        h3: 0,
                        rbi: 0,
                        bb: 0,
                        so: 0,
                        hr: 0,
                        r: 0,
                        sb: 0,
                        cs: 0,
                    };
                }
                if (isPlateAppearance) {
                    playerStats[pitcherID].plateAppearances += 1;
                    if (resultType === resultTypes.H1) playerStats[pitcherID].h1 += 1;
                    if (resultType === resultTypes.H2) playerStats[pitcherID].h2 += 1;
                    if (resultType === resultTypes.H3) playerStats[pitcherID].h3 += 1;
                    if (resultType === resultTypes.HR) playerStats[pitcherID].hr += 1;
                    if (resultType === resultTypes.BB) playerStats[pitcherID].bb += 1;
                    if (resultType === resultTypes.SO) {
                        playerStats[pitcherID].so += 1;
                        playerStats[pitcherID].ipOuts += 1;
                    }
                    if (resultType === resultTypes.OO) {
                        playerStats[pitcherID].oo += 1;
                        playerStats[pitcherID].ipOuts += 1;
                    }
                    if (event.rbi) playerStats[pitcherID].rbi += event.rbi;
                }

                //TODO runs and steals don't need a PA
                if (event.runs) playerStats[pitcherID].r += event.runs;
                if (event.sb) playerStats[pitcherID].sb += event.sb;
                if (event.cs) playerStats[pitcherID].cs += event.cs;
            }
        });

        return playerStats;
    };

    const boxScore = generateBoxScore(game);

    return (
        <div className="game-summary-page">
            <h2>Game Summary</h2>
            {game && (
                <div>
                    <h4>
                        {homeParticipant && (
                            <span>
                                Home: {homeParticipant.UserTeam?.name} - {latestEvent?.homeScore} &nbsp;&nbsp;&nbsp;
                            </span>
                        )}
                        {awayParticipant && (
                            <span>
                                Visitor: {awayParticipant.UserTeam?.name} - {latestEvent?.visitingScore}
                            </span>
                        )}
                    </h4>
                    <button onClick={toggleBoxScore}>{boxScoreExpanded ? 'Hide Box Score' : 'Show Box Score'}</button>
                    {boxScoreExpanded && (
                        <div className="box-score">
                            <h3>Box Score</h3>
                            {game.GameParticipants?.map(participant => (
                                <div key={participant.gameParticipantID} className="participant">
                                    <h4>
                                        {participant.role} Team: {participant.UserTeam?.name}{' '}
                                        {participant.userID ? '' : <FontAwesomeIcon icon={faRobot} />}
                                    </h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Order</th>
                                                <th>Card</th>
                                                <th>Position</th>
                                                <th>AB / IP</th>
                                                <th>H1</th>
                                                <th>H2</th>
                                                <th>H3</th>
                                                <th>HR</th>
                                                <th>R</th>
                                                <th>RBI</th>
                                                <th>BB</th>
                                                <th>SO</th>
                                                <th>SB</th>
                                                <th>CS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortLineup(participant.GameLineups)?.map(lineup => {
                                                const lineupID = lineup.gameLineupID;
                                                //TODO: it's not that simple, if a player started the game, but was instantly removed?
                                                if (!boxScore[lineupID]?.plateAppearances) {
                                                    return;
                                                }

                                                return (
                                                    <tr key={lineupID}>
                                                        <td>
                                                            {lineup.lineupOrder !== null ? lineup.lineupOrder : 'P'}
                                                        </td>
                                                        <td>
                                                            {lineup.Card?.year} {lineup.Card?.name} (
                                                            {lineup.Card?.rating})
                                                        </td>
                                                        <td>{lineup.fieldingPosition}</td>
                                                        <td>
                                                            {boxScore[lineupID]?.ab ||
                                                                (boxScore[lineupID]?.ipOuts / 3).toFixed(1) ||
                                                                0}
                                                        </td>
                                                        <td>{boxScore[lineupID]?.h1 || 0}</td>
                                                        <td>{boxScore[lineupID]?.h2 || 0}</td>
                                                        <td>{boxScore[lineupID]?.h3 || 0}</td>
                                                        <td>{boxScore[lineupID]?.hr || 0}</td>
                                                        <td>{boxScore[lineupID]?.r || 0}</td>
                                                        <td>{boxScore[lineupID]?.rbi || 0}</td>
                                                        <td>{boxScore[lineupID]?.bb || 0}</td>
                                                        <td>{boxScore[lineupID]?.so || 0}</td>
                                                        <td>{boxScore[lineupID]?.sb || 0}</td>
                                                        <td>{boxScore[lineupID]?.cs || 0}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>
                    )}
                    <button onClick={toggleEvents}>{eventsExpanded ? 'Hide Game Events' : 'Show Game Events'}</button>
                    {/* add in when the players were subed */}
                    {eventsExpanded && (
                        <div className="events-container">
                            <h3>All Game Events:</h3>
                            <table className="table-striped">
                                <thead>
                                    <tr>
                                        <th>Score</th>
                                        <th>Outs</th>
                                        <th>Result</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {game.GameEvents?.sort((a, b) => a.gameEventID - b.gameEventID).map(event => {
                                        const sub = findSub(event.gameEventID, game);
                                        return (
                                            <>
                                                <tr key={event.gameEventID}>
                                                    <td>
                                                        H:{event.homeScore} - V:{event.visitingScore}
                                                    </td>
                                                    <td>{translateOuts(event.ipOuts).complete}</td>
                                                    <td>{event.resultType}</td>
                                                    <td>{event.resultDescription}</td>
                                                </tr>
                                                {sub ? (
                                                    <tr>
                                                        <td colSpan="4">{sub}</td>
                                                    </tr>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default GameSummaryPage;
