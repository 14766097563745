import React from 'react';

function Footer() {
    const appVersion = '0.0.19-fe';
    return (
        <footer>
            <span>App Version: {appVersion}</span>
        </footer>
    );
}

export default Footer;
