import React, { useEffect, useState } from 'react';
import { fetchDupliateCardsByUserID, sellDuplicateCards } from '../../api/cards';

const SellCardsPage = () => {
    const [duplicates, setDuplicates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [totalValue, setTotalValue] = useState(0);

    const cardValue = rating => {
        let sellPrice = 1;
        if (rating >= 96) {
            sellPrice = 800;
        } else if (rating >= 86) {
            sellPrice = 200;
        } else if (rating >= 76) {
            sellPrice = 100;
        } else if (rating >= 66) {
            sellPrice = 10;
        }
        return sellPrice;
    };

    const fetchUserDuplicates = async () => {
        setError(null);
        try {
            const data = await fetchDupliateCardsByUserID();
            const duplicateCards = data.cards.filter(card => card.amount > 1); // Filter duplicates

            // Map through the duplicates and assign a sell price
            const calculatedDuplicates = duplicateCards.map(card => {
                const rating = card.Card.rating;

                return {
                    ...card,
                    sellPrice: cardValue(rating),
                    totalSellPrice: cardValue(rating) * (card.amount - 1), // Deduct 1 as one card must be kept
                };
            });

            setDuplicates(calculatedDuplicates);
            const total = calculatedDuplicates.reduce((sum, card) => sum + card.totalSellPrice, 0);
            setTotalValue(total);
        } catch (error) {
            setError('Error fetching user cards');
            console.error('Error fetching user cards:', error);
        }
    };

    useEffect(() => {
        fetchUserDuplicates();
    }, []);

    const handleSellDuplicates = async () => {
        setLoading(true);
        try {
            await sellDuplicateCards(); // Assuming this function triggers the sale
            fetchUserDuplicates(); // Re-fetch the duplicates after selling
        } catch (error) {
            console.error('Error selling duplicate cards:', error);
            setError('Error selling duplicate cards');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="sell-duplicates-page">
            {error && <p className="error">{error}</p>}

            <>
                <h2>Sell Duplicate Cards</h2>
                <p>
                    Total Value: ${totalValue} &nbsp;
                    <button
                        className="sell-button"
                        onClick={handleSellDuplicates}
                        disabled={totalValue <= 0 || loading}
                    >
                        {loading ? 'Selling...' : 'Sell All Duplicates'}
                    </button>
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Rating</th>
                            <th>Qty</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {duplicates.map(inv => (
                            <tr key={inv.inventoryID}>
                                <td>
                                    {inv.Card.year} {inv.Card.name}
                                </td>
                                <td>{inv.Card.rating}</td>
                                <td>{inv.amount - 1}</td>
                                <td>{inv.totalSellPrice}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        </div>
    );
};

export default SellCardsPage;
