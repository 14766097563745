import React, { useState, useEffect } from 'react';

const FlipBoard = ({ result, flip, flipDuration = 1000, height = '40px', width = '100%', strikeThrough = false }) => {
    const [flipped, setFlipped] = useState(false);
    const strikeThroughStyle = {
        textDecoration: 'line-through',
    };
    useEffect(() => {
        if (flip === false) {
            setFlipped(false); // Instantly flip to the back (blank)
        } else if (flip === true) {
            setTimeout(() => {
                setFlipped(true); // Flip to show the result after the duration
            }, flipDuration);
        }
    }, [flip, flipDuration]);

    return (
        <div className={`flip-board ${flipped ? 'flipped' : ''}`} style={{ height, width }}>
            <div className="flip-board-inner">
                <div className="flip-board-front"></div>
                <div className="flip-board-back">
                    <span className="flip-result" style={strikeThrough ? strikeThroughStyle : {}}>
                        {result}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FlipBoard;
