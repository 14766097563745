import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSeries } from '../../api/series';
import { buyPack } from '../../api/packInventory';
import { fetchWallet } from '../../api/wallet';

const BuyPacksPage = () => {
    const [packs, setPacks] = useState([]);
    const [wallet, setWallet] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchInfo = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchAllSeries();
            setPacks(data);
            const walletData = await fetchWallet();
            setWallet(walletData.balance);
        } catch (error) {
            setError('Error fetching packs');
            toast.error('Error fetching packs: ' + error.message);
            console.error('Error fetching packs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInfo();
    }, []);

    const handleBuyPack = async (series, quantity) => {
        try {
            await buyPack(series.seriesID, quantity);
            setWallet(wallet - quantity * series.cost);
            toast.success(`${quantity} pack(s) purchased successfully!`);
        } catch (error) {
            setError('Error purchasing pack');
            toast.error('Error purchasing pack: ' + error.message);
            console.error('Error purchasing pack:', error);
        }
    };

    const getPackImageSrc = key => `/img/card/${key}.png`;

    return (
        <div className="buy-packs-page">
            <div className="header-info">
                <span className="header-item">
                    <Link to="/packs/user">My Packs</Link> / Buy Packs
                </span>
                <span className="header-item">${wallet}</span>
            </div>
            {error && <p className="error">{error}</p>}
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="packs-grid">
                    {packs.map(pack => (
                        <div key={pack.seriesID} className="pack-item">
                            <img
                                src={getPackImageSrc(pack.seriesKey)}
                                alt={`${pack.name} pack art`}
                                className="pack-image"
                                onError={e => (e.target.src = '/img/card/default.png')}
                            />
                            <div className="pack-details">
                                <span className="pack-title">
                                    {pack.name} ({pack.year})
                                </span>
                                <span className="pack-price"> (${pack.cost}) </span>
                                <div className="pack-buttons">
                                    <button onClick={() => handleBuyPack(pack, 1)}>Buy 1</button>
                                    <button onClick={() => handleBuyPack(pack, 10)}>Buy 10</button>
                                    <button onClick={() => handleBuyPack(pack, 25)}>Buy 25</button>
                                    <button onClick={() => handleBuyPack(pack, 50)}>Buy 50</button>
                                    <button onClick={() => handleBuyPack(pack, 100)}>Buy 100</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default BuyPacksPage;
