import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { register } = useAuth();

    const validatePassword = password => {
        const minLength = 8;
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);

        const errors = [];
        if (password.length < minLength) errors.push(`Password must be at least ${minLength} characters long`);
        if (!hasNumber) errors.push('Password must contain at least 1 number');
        if (!hasSpecialChar) errors.push('Password must contain at least 1 special character');
        if (!hasUpperCase) errors.push('Password must contain at least 1 uppercase letter');
        if (!hasLowerCase) errors.push('Password must contain at least 1 lowercase letter');

        return errors;
    };

    const handleRegister = async e => {
        e.preventDefault();

        const passwordErrors = validatePassword(password);
        if (passwordErrors.length > 0) {
            passwordErrors.forEach(error => toast.error(error));
            return;
        }

        try {
            await register(username, email, password);
            toast.success('Registration successful');
            //window.location.href = '/login';
        } catch (error) {
            toast.error('Registration failed: ' + error.message);
        }
    };

    return (
        <div>
            <h2>Register</h2>
            <p>IMPORTANT: after you register, look for a confirmation email to be able to login</p>
            <form onSubmit={handleRegister}>
                <label>
                    Username:
                    <input type="text" value={username} onChange={e => setUsername(e.target.value)} required />
                </label>
                <label>
                    Email:
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                </label>
                <button type="submit">Register</button>
            </form>
            <h3>Password requirements</h3>
            <p>
                <ul>Password minimum length 8 character(s)</ul>
                <ul>Contains at least 1 number</ul>
                <ul>Contains at least 1 special character</ul>
                <ul>Contains at least 1 uppercase letter</ul>
                <ul>Contains at least 1 lowercase letter</ul>
            </p>
        </div>
    );
};

export default Register;
