import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
TODO: 
- add a way to take the JWT token when you login to AWS cognito, and be able to pass it to API requests.
- store JWT data in browser storage
- add XSS headers to the browser for security

- allow for HTTPS:
  - ask chatgpt: How do I add certificates to allow HTTPS on nerd website?

- Add Teams
- Add Packs
- Add Game

*/