import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { fetchUserCampaignContestByID, resignFromCampaignContest } from '../../api/campaigns';
import { fetchUserTeams } from '../../api/userTeams';
import { createCampaignGame } from '../../api/games';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PlayCampaignContestPage = () => {
    const { userCampaignContestID } = useParams();
    const navigate = useNavigate();
    const [campaignContest, setCampaignContest] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userTeams, setUserTeams] = useState([]);
    const [selectedUserTeam, setSelectedUserTeam] = useState('');

    useEffect(() => {
        const fetchContestDetails = async () => {
            setLoading(true);
            setError(null);
            try {
                const contestData = await fetchUserCampaignContestByID(userCampaignContestID);
                setCampaignContest(contestData);
            } catch (error) {
                setError('Error fetching campaign contest');
                toast.error('Error fetching campaign contest: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchContestDetails();
    }, [userCampaignContestID]);

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const teamsData = await fetchUserTeams();
                setUserTeams(teamsData);
            } catch (error) {
                toast.error('Error fetching user teams: ' + error.message);
            }
        };

        fetchTeams();
    }, []);

    const handleTeamChange = e => {
        setSelectedUserTeam(e.target.value);
    };

    const handlePlayNextGame = async () => {
        if (!selectedUserTeam) {
            toast.error('Please select a team');
            return;
        }

        try {
            const newGame = await createCampaignGame({
                userCampaignContestID,
                homeUserTeamID: selectedUserTeam,
                visitingUserTeamID: campaignContest.CampaignContest.userTeamID,
            });

            navigate(`/game/${newGame.gameID}/play`);
        } catch (error) {
            toast.error('Error creating game: ' + error.message);
        }
    };

    const handleResignFromSeries = async () => {
        const confirmResignation = window.confirm('Are you sure you want to resign from this series?');
        if (!confirmResignation) return;

        try {
            await resignFromCampaignContest(userCampaignContestID);
            toast.success('You have resigned from the campaign contest.');
            navigate(`/campaigns/${campaignContest.CampaignContest.campaignID}`);
        } catch (error) {
            toast.error('Error resigning from the campaign contest: ' + error.message);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    if (!campaignContest) {
        return <p>No campaign contest found.</p>;
    }

    const { Games, CampaignContest } = campaignContest;
    const totalGames = CampaignContest.numOfGames;

    const gamePlaceholders = Array.from({ length: totalGames }, (_, index) => {
        const game = Games[index];
        if (game) {
            return (
                <tr key={game.gameID}>
                    <td>{game.gameID}</td>
                    <td>{new Date(game.created).toLocaleDateString()}</td>
                    <td>
                        {game.resultDescription ? (
                            <Link to={`/game/${game.gameID}/summary`}>{game.resultDescription}</Link>
                        ) : (
                            <Link to={`/game/${game.gameID}/play`}>Play</Link>
                        )}
                    </td>
                </tr>
            );
        } else {
            return (
                <tr key={index}>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            );
        }
    });

    return (
        <div className="play-campaign-contest-page">
            <h2>Play Campaign Contest</h2>
            <div>
                <h3>{CampaignContest.name}</h3>
                <div>{CampaignContest.desc}</div>
                <div>
                    <br />
                    <b>Score:</b> {campaignContest.wins} - {campaignContest.losses}
                </div>
                {campaignContest.resultDescription ? <div>{campaignContest.result}</div> : <></>}
            </div>
            <div>
                <h3>Games</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Game ID</th>
                            <th>Date</th>
                            <th>Result</th>
                        </tr>
                    </thead>
                    <tbody>{gamePlaceholders}</tbody>
                </table>
            </div>
            <div>
                {Games.length < totalGames ? (
                    <div>
                        <select value={selectedUserTeam} onChange={handleTeamChange}>
                            <option value="">Select your team</option>
                            {userTeams.map(team => (
                                <option key={team.userTeamID} value={team.userTeamID}>
                                    {team.name}
                                </option>
                            ))}
                        </select>
                        <button onClick={handlePlayNextGame}>Play Next Game</button>
                    </div>
                ) : (
                    <p>All games in the series have been played.</p>
                )}
            </div>
            <div>
                <span onClick={handleResignFromSeries} className="fake_link">
                    Resign from Series
                    <br />
                </span>
            </div>
            <div>
                <Link to={`/campaigns/${campaignContest.CampaignContest.campaignID}`}>Back to Campaign Contests</Link>
            </div>
        </div>
    );
};

export default PlayCampaignContestPage;
