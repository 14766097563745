// src/components/SortableTable.js
import React from 'react';
import PropTypes from 'prop-types';

const SortableTable = ({ data, requestSort, sortConfig, columns }) => {
    const getClassNamesFor = name => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((o, i) => (o ? o[i] : null), obj);
    };

    return (
        <table>
            <thead>
                <tr>
                    {columns.map(column => (
                        <th
                            key={column.key}
                            onClick={() => requestSort(column.key)}
                            className={getClassNamesFor(column.key)}
                        >
                            {column.label}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map(item => (
                    <tr key={item.cardID}>
                        {columns.map(column => (
                            <td key={column.key}>
                                {column.render ? column.render(item) : getNestedValue(item, column.key)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

SortableTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    requestSort: PropTypes.func.isRequired,
    sortConfig: PropTypes.shape({
        key: PropTypes.string,
        direction: PropTypes.string,
    }).isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            render: PropTypes.func,
        })
    ).isRequired,
};

export default SortableTable;
