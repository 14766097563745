import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from './routes';
import './css/nerd.css';
import Layout from './components/Layout';
import { AuthProvider } from './contexts/AuthContext';
import CombinedProvider from './contexts/CombinedProvider';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
    return (
        <React.StrictMode>
            <Router>
                <AuthProvider>
                    <CombinedProvider>
                        <Layout>
                            <Routes>
                                {routes.map(({ path, component: Component, private: isPrivate, admin: isAdmin }) =>
                                    isPrivate ? (
                                        <Route key={path} path={path} element={<PrivateRoute />}>
                                            <Route path={path} element={<Component />} />
                                        </Route>
                                    ) : isAdmin ? (
                                        <Route key={path} path={path} element={<AdminRoute />}>
                                            <Route path={path} element={<Component />} />
                                        </Route>
                                    ) : (
                                        <Route key={path} path={path} element={<Component />} />
                                    )
                                )}
                            </Routes>
                            <ToastContainer />
                        </Layout>
                    </CombinedProvider>
                </AuthProvider>
            </Router>
        </React.StrictMode>
    );
}
