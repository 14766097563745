// src/pages/AboutPage.js
import React from 'react';

const AboutPage = () => {
    return (
        <div className="container">
            <h1 className="header">FAQ</h1>
            <h3>Upcoming Features</h3>
            <div className="paragraph">
                <ul>
                    <li>Pitchers stamina matters</li>
                    <li>Monthly / Quarterly Leaderboards</li>
                    <li>Play other user teams</li>
                    <li>Tournaments with other teams</li>
                    <li>More campaigns</li>
                </ul>
            </div>
            <h3>Bug Reporting</h3>
            <div className="paragraph">
                Just tell Dan at the{' '}
                <a href="https://discord.gg/HuAJc3bP" target="_blank" rel="noopener noreferrer">
                    discord server
                </a>{' '}
                for now.
            </div>
            <h3>Known Issues</h3>
            <div>
                <ul>
                    <li>subs are limited for know, you can only sub for a player that plays the same position</li>
                </ul>
            </div>
            <h3>.24 release</h3>
            <div>
                <ul>
                    <li>Try to steal a base</li>
                    <li>runs (and steals and cs) should now be assigned to the correct batter.</li>
                    <li>Steals and Caught Stealing now on the leaderboard</li>
                </ul>
            </div>
            <h3>.23 release</h3>
            <div>
                <ul>
                    <li>Accurate hit locations</li>
                    <li>Try to take an extra base</li>
                    <li>Try to tag up</li>
                    <li>No longer add the same card to two different positions</li>
                </ul>
            </div>
        </div>
    );
};

export default AboutPage;
