import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchLeaderboard } from '../../api/cards';
import { getCardRarityClass } from '../../utils/cardUtils';

const LeaderboardPage = () => {
    const [leaderboard, setLeaderboard] = useState({ batters: [], pitchers: [] });

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchLeaderboard();
            setLeaderboard(data);
        };

        fetchData();
    }, []);

    return (
        <div className="leader-container">
            <h1 className="header">Leaderboard</h1>

            {/* Batters Section */}
            <div>
                <h2>Batters</h2>
            </div>
            <div className="leaderboard-grid">
                {leaderboard.batters.map(statCategory => (
                    <div key={statCategory.stat} className="stat-category">
                        <h3>{statCategory.stat.toUpperCase()}</h3>
                        <ul>
                            {statCategory.players.map(player => (
                                <li key={player.cardID}>
                                    <Link to={`/profile/${player.User.userID}`}>{player.User.username}</Link>
                                    &nbsp;
                                    <span className={`square ${getCardRarityClass(player.Card.rating)}`}></span>(
                                    {player.Card.rating}) {player.Card.year} {player.Card.name}:{' '}
                                    {player[statCategory.stat]} (in {player.ab} AB)
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            {/* Pitchers Section */}
            <div>
                <h2>Pitchers</h2>
            </div>
            <div className="leaderboard-grid">
                {leaderboard.pitchers.map(statCategory => (
                    <div key={statCategory.stat} className="stat-category">
                        <h3>{statCategory.stat.toUpperCase()}</h3>
                        <ul>
                            {statCategory.players.map(player => (
                                <li key={player.cardID}>
                                    <Link to={`/profile/${player.User.userID}`}>{player.User.username}</Link>
                                    &nbsp;
                                    <span className={`square ${getCardRarityClass(player.Card.rating)}`}></span>(
                                    {player.Card.rating}) {player.Card.year} {player.Card.name}:{' '}
                                    {player[statCategory.stat]} (in {((player.ipOuts || 0) / 3).toFixed(1)} IP)
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LeaderboardPage;
