// src/pages/ArtistPage.js
import React from 'react';

const ArtistPage = () => {
    return (
        <div className="container">
            <h1 className="header">ArtistPage</h1>
            <p className="paragraph">Artists will go here.</p>
        </div>
    );
};

export default ArtistPage;
