import axios from './axios';

export const fetchUserPacks = async () => {
    try {
        const response = await axios.get(`/packInventory/user`);
        return response.data.filter(pack => pack.amount > 0);
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const buyPack = async (seriesID, amount) => {
    try {
        const response = await axios.post('/packInventory/buy', {
            seriesID,
            amount,
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};

export const openPack = async ({ seriesID }) => {
    try {
        const response = await axios.post('/packInventory/open', { seriesID });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
    }
};
